import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { TranslationService } from 'src/app/providers/translation.service';
import { Subject, takeUntil } from 'rxjs';
import { LicenseService } from 'src/app/providers/license.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activate-license',
  templateUrl: './activate-license.component.html',
  styleUrls: ['./activate-license.component.scss'],
})
export class ActivateLicenseComponent implements OnInit {
  translatedText: any;
  licenses;
  licenses_Type: string;
  expiry_Date: Date;
  content_Type: string;
  ExpiryDate: string;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  licensesToSchool: any[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ActivateLicenseComponent>,
    private changeDetector: ChangeDetectorRef,
    private translationService: TranslationService,
    private licenseService: LicenseService,
  ) {
    if (injectedData) {
      if (typeof injectedData['licenses'] !== 'undefined') {
        this.licenses = this.injectedData['licenses'];
      }
    }
    console.log(this.licenses);
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  getLicenseAmount(tag, type) {
    let amount = 0;
    if (this.licenses.length > 0) {
      this.licenses.map((item) => {
        if (item.type_key === tag) {
          if (type === 'total') {
            let expirationDate = new Date(
              Date.now() + 365 * 24 * 60 * 60 * 1000,
            );
            if (environment.whitelabel.name.toLowerCase() == 'lesecoach') {
              const oneYearFromNow = new Date();
              oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1); // Add 1 year

              const oneYearOneMonthFromNow = new Date(oneYearFromNow);
              oneYearOneMonthFromNow.setMonth(
                oneYearOneMonthFromNow.getMonth() + 1,
              ); // Add 1 month
              expirationDate = oneYearOneMonthFromNow;
            }
            amount += item.amount;
            this.content_Type = this.licenseService.getContentTypeByLicenseKey(
              item.master_license_key,
              this.translatedText,
            );
            this.expiry_Date = expirationDate;
            this.licenses_Type =
              item.amount == 1
                ? this.translatedText.licenses_purchase_type_single
                : item.amount >= '100'
                  ? this.translatedText.licenses_purchase_type_school_plural
                  : this.translatedText.licenses_purchase_type_single_plural;
          } else {
            amount += item.amount_left;
          }
        }
      });
    }
    return Math.floor(amount);
  }

  checkForUnlimitedDiagnosticLicense() {
    return this.licenseService.checkForUnlimitedDiagnosticLicense(
      'di',
      this.licenses,
      false,
    );
  }

  activateLicenses() {
    const masterLicenseKey = this.licenses[0]?.master_license_key;
    if (masterLicenseKey) {
      this.licenseService
        .activateLicenses(masterLicenseKey)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.dialogRef.close('activated');
        });
    }
  }

  closePopup() {
    this.dialog.closeAll();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
