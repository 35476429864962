import { Component, Inject, OnInit, Optional } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminService, GroupData } from 'src/app/providers/admin.service';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { SuccessSnackbarComponent } from '../../helpers/snackbar/success-snackbar/success-snackbar.component';
import { AuthService } from 'src/app/auth/auth.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-license-add',
  templateUrl: './license-add.component.html',
  styleUrls: ['./license-add.component.scss'],
})
export class LicenseAddComponent implements OnInit {
  licenseForm: FormGroup;
  rForm: FormGroup;
  isFormView: boolean = true;
  licenseView: boolean = false;
  isApplyView: boolean = false;
  displayedColumns: string[] = ['license_type', 'amount'];
  dataSource: any;
  isLoading: boolean = false;
  licenseInfo: any = [];
  licenseError: boolean = false;
  licensesToSchool: any = [];
  licenseRedeemed: string = '';
  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';
  groups;
  translatedText: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private adminService: AdminService,
    private licenseService: LicenseService,
    private capacitorService: CapacitorService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.licenseForm = this.formBuilder.group({
      license: ['', Validators.required],
    });

    this.rForm = this.formBuilder.group({
      groups: [null, Validators.required],
      type_key: ['di', Validators.required],
      amount: [0, Validators.required],
    });
    this.getGroups();
  }

  getGroupName() {
    const selectedGroupId = this.rForm.get('groups').value;
    const selectedGroup = this.groups.find(
      (group) => group._id === selectedGroupId,
    );
    return selectedGroup
      ? selectedGroup.name + selectedGroup.school_year.slice(2)
      : '';
  }

  getGroups() {
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .subscribe((groupData: GroupData) => {
        this.groups = groupData.data;
      });
  }

  submitForm(form: FormGroupDirective) {
    if (this.licenseForm.invalid) {
      return;
    }
    this.isLoading = true;

    this.licenseService
      .generateSubLicenses(this.licenseForm.value.license)
      ?.pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (licenses: any) => {
          this.licensesToSchool = licenses;
          this.authService.disableTrialPhase();
          this.licenseInfo = this.licenseService.getLicenseInfo();
          this.licenseView = true;
          this.isFormView = false;
          // data for table
          //this.dataSource  = response;
          this.isLoading = false;
          // Reset the form
          this.licenseForm.reset();
        },
        error: (error: any) => {
          this.isLoading = false;
          this.licenseError = true;
          console.log(error);
        },
      });
  }

  getLicenseAmountWithText(tag) {
    let license = this.licenseInfo.filter((item) => item.tag === tag);
    let amount = license.length > 0 ? license[0].amount : 0;
    if (
      tag == 'di' &&
      this.licenseService.checkForUnlimitedDiagnosticLicense(
        tag,
        this.licensesToSchool,
        false,
      )
    ) {
      amount = '∞';
    }
    return amount.toString();
  }

  getLicenseAmount(tag, type) {
    let amount = 0;
    if (this.licensesToSchool.length > 0) {
      this.licensesToSchool.map((el) => {
        if (el.type_key === tag) {
          if (type === 'total') {
            amount += el.amount;
          } else {
            amount += el.amount_left;
          }
        }
      });
      if (tag === 'fo') {
        amount = amount > 0 ? amount / 60 : amount;
      }
    }
    return amount;
  }

  closeOverlay() {
    // Reset the form
    this.licenseForm.reset();
    this.dialog.closeAll();
  }

  openUrl(url: string) {
    this.capacitorService.openUrl(url);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
