import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginComponent } from './user/login/login.component';
import { QrLoginComponent } from './user/qr-login/qr-login.component';
import { DashboardStudentComponent } from './dashboard/dashboard-student/dashboard-student.component';
import { DashboardTeacherComponent } from './dashboard/dashboard-teacher/dashboard-teacher.component';
import { BookListComponent } from './books/new-book-list/book-list.component';
import { BookViewComponent } from './books/book-view/book-view.component';
import { BookPreviewComponent } from './books/book-preview/book-preview.component';
import { BookReaderViewComponent } from './book-reader/book-reader-view/book-reader-view.component';
import { RecordRTCComponent } from './record-rtc/record-rtc.component';
import { CreatePasswordComponent } from './user/create-password/create-password.component';
import { ForgotPasswordComponent } from './user/forgot-password/forgot-password.component';
import { ClassDetailComponent } from './class/class-detail/class-detail.component';
import { StudentOverviewComponent } from './student/student-overview/student-overview.component';
import { StudentEditComponent } from './student/student-edit/student-edit.component';
import { StudentResultsComponent } from './student/student-results/student-results.component';
import { StudentReportComponent } from './student/student-report/student-report.component';
import { TaskListComponent } from './task/task-list/task-list.component';
import { TaskDetailComponent } from './task/task-detail/task-detail.component';
import { PrivacyComponent } from './static/privacy/privacy.component';
import { SurveysComponent } from './static/privacy/surveys/surveys.component';
import { ReleaseNotesComponent } from './static/release-notes/release-notes.component';
import { TermsOfUseComponent } from './static/terms-of-use/terms-of-use.component';
import { TermsAndConditionsComponent } from './static/terms-and-conditions/terms-and-conditions.component';
import { OrderProcessingAgreementComponent } from './static/order-processing-agreement/order-processing-agreement.component';
import { HomeComponent } from './static/home/home.component';
import { HomeLoginComponent } from './static/home-login/home-login.component';
import { AboutUsComponent } from './static/about-us/about-us.component';
import { ContactComponent } from './static/contact/contact.component';
import { ImpressumComponent } from './static/impressum/impressum.component';
import { DashboardTasksComponent } from './dashboard/dashboard-tasks/dashboard-tasks.component';
import { TaskPreviewComponent } from './task/task-preview/task-preview.component';
import { BookListStudentComponent } from './books/book-list-student/book-list-student.component';
import { BookPreviewStudentComponent } from './books/book-preview-student/book-preview-student.component';
import { BookViewStudentComponent } from './books/book-view-student/book-view-student.component';
import { AdminDashboardComponent } from './admin/new-admin-dashboard/admin-dashboard.component';
import { AdminTeacherListComponent } from './admin/admin-teacher/admin-teacher-list/admin-teacher-list.component';
import { AdminGroupListComponent } from './admin/admin-group/admin-group-list/admin-group-list.component';
import { AdminManagementComponent } from './admin/admin-management/admin-management.component';
import { AdminTeacherCreateComponent } from './admin/admin-teacher/new-admin-teacher-create/admin-teacher-create.component';
import { SchoolAdminCreateTrialComponent } from './admin/school-admin/school-admin-create-trial/school-admin-create-trial.component';
import { SchoolAdminCreateNoSchoolComponent } from './admin/school-admin/school-admin-create-no-school/school-admin-create-no-school.component';
import { DashboardLicenseComponent } from './dashboard/dashboard-license/dashboard-license.component';
import { DashboardDiagnosticsComponent } from './dashboard/dashboard-diagnostics/dashboard-diagnostics.component';
import { AdminListComponent } from './admin/admin-list/admin-list.component';
import { ParentsLandingPageComponent } from './parents/parents-landing-page/parents-landing-page.component';
import { DashboardHelpDeskComponent } from './dashboard/dashboard-help-desk/dashboard-help-desk.component';
import { SchoolDetailComponent } from './school/school-detail/school-detail.component';
import { NonSchoolregisterComponent } from './school/non-school-register/non-school-register.component';
import { RecordResultComponent } from './record-rtc/record-result/record-result.component';
import { IsStudentGuard } from './auth/is-student.guard';
import { translationResolver } from './resolvers/i18n.resolver';
import { AuthenticatorSetupComponent } from './user/authenticator-setup/authenticator-setup.component';
import { AdminProfileManagementComponent } from './admin/admin-profile-management/admin-profile-management.component';

const register_forms = {
  'school-germany': SchoolAdminCreateTrialComponent,
  'no-school-swiss': SchoolAdminCreateNoSchoolComponent,
};

const ROUTES: Routes = [
  {
    path: '',
    redirectTo: environment.whitelabel.landingpage,
    pathMatch: 'full',
  },
  {
    path: '',
    resolve: { translation: translationResolver },
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'login/:role', component: LoginComponent },
      { path: 'qr/login/:token', component: QrLoginComponent },
      {
        path: 'dashboard-student',
        component: DashboardStudentComponent,
        canActivate: [IsStudentGuard],
      },
      { path: 'dashboard-teacher', component: DashboardTeacherComponent },
      { path: 'dashboard-help-desk', component: DashboardHelpDeskComponent },
      { path: 'books', component: BookListComponent },
      { path: 'books/:id', component: BookViewComponent },
      { path: 'book-preview/:id', component: BookPreviewComponent },
      { path: 'book-reader', component: BookReaderViewComponent },
      { path: 'reading', component: RecordRTCComponent },
      {
        path: 'create-password/:resetToken',
        component: CreatePasswordComponent,
      },
      {
        path: 'reset-password/:resetToken',
        component: CreatePasswordComponent,
      },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'forgot-password/:role', component: ForgotPasswordComponent },
      { path: 'class-detail/:id', component: ClassDetailComponent },
      { path: 'student-overview/:id', component: StudentOverviewComponent },
      { path: 'student-edit/:id', component: StudentEditComponent },
      {
        path: 'student-results',
        component: StudentResultsComponent,
        canActivate: [IsStudentGuard],
      },
      { path: 'student-report/:id', component: StudentReportComponent },
      { path: 'task-list', component: TaskListComponent },
      { path: 'task-detail/:id', component: TaskDetailComponent },
      { path: 'release-notes', component: ReleaseNotesComponent },
      { path: 'datenschutz', component: PrivacyComponent },
      { path: 'datenschutz/umfragen', component: SurveysComponent },
      { path: 'nutzungsbedingungen', component: TermsOfUseComponent },
      { path: 'agb', component: TermsAndConditionsComponent },
      { path: 'avv', component: OrderProcessingAgreementComponent },
      { path: 'kontakt', component: ContactComponent },
      { path: 'impressum', component: ImpressumComponent },
      { path: 'ueber-uns', component: AboutUsComponent },
      { path: 'dashboard-tasks', component: DashboardTasksComponent },
      { path: 'task-preview/:id', component: TaskPreviewComponent },
      { path: 'book-list-student/:id', component: BookListStudentComponent },
      {
        path: 'book-preview-student/:id',
        component: BookPreviewStudentComponent,
      },
      { path: 'book-view-student/:id', component: BookViewStudentComponent },
      { path: 'admin-dashboard', component: AdminDashboardComponent },
      { path: 'admin-teacher-list', component: AdminTeacherListComponent },
      { path: 'admin-teacher-create', component: AdminTeacherCreateComponent },
      { path: 'admin-group-list', component: AdminGroupListComponent },
      { path: 'admin-management', component: AdminManagementComponent },
      {
        path: 'admin-profile-management',
        component: AdminProfileManagementComponent,
      },
      {
        path: 'schulregistrierung',
        component: register_forms[environment.whitelabel.register_form],
      },
      {
        path: 'registrierung',
        component: register_forms[environment.whitelabel.register_form],
      },
      { path: 'dashboard-license', component: DashboardLicenseComponent },
      {
        path: 'dashboard-diagnostics',
        component: DashboardDiagnosticsComponent,
      },
      { path: 'admin-list', component: AdminListComponent },
      { path: 'family', component: ParentsLandingPageComponent },
      { path: 'school-detail/:id', component: SchoolDetailComponent },
      { path: 'non-school-register', component: NonSchoolregisterComponent },
      {
        path: 'internal-testcase-record-result/:level',
        component: RecordResultComponent,
      },
      { path: 'authenticator-setup', component: AuthenticatorSetupComponent },
    ],
  },
];

// Change the home route based on the environment
if (environment.isApp) {
  ROUTES[1]['children'].push({ path: 'home', component: HomeLoginComponent });
} // Disable landing page and take user to registration page
else if (environment.whitelabel.landingpage === 'registrierung') {
  ROUTES[1]['children'].push({
    path: 'home',
    redirectTo: environment.whitelabel.landingpage,
    pathMatch: 'full',
  });
} else {
  ROUTES[1]['children'].push({ path: 'home', component: HomeComponent });
}

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
