import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription, Subject, takeUntil } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivateLicenseComponent } from 'src/app/helpers/activate-license/activate-license.component';

@Component({
  selector: 'app-admin-profile-license',
  templateUrl: './admin-profile-license.component.html',
  styleUrls: ['./admin-profile-license.component.scss'],
})
export class AdminProfileLicenseComponent implements OnInit, OnChanges {
  translatedText: any;
  @Input() licensesToSchool: any = [];
  @Input() payload: any = [];
  @Output() refreshEvent = new EventEmitter<string>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  page = 1;
  limit = 10;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  dataSource = null;
  pageEvent: PageEvent;
  displayedColumns: string[] = [
    'Content',
    'quota',
    'Activated on',
    'Expiry Date',
    'Activate',
  ];
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  constructor(
    private licenseService: LicenseService,
    private translationService: TranslationService,
    public dialog: MatDialog,
  ) {}
  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  activateLicenseOverlay(master_license_key) {
    let dialogRef = this.dialog.open(ActivateLicenseComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'activate-license-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'bg-group-delete-overlay',
      data: {
        licenses: this.licensesToSchool.filter(
          (item) => item.master_license_key == master_license_key,
        ),
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'activated') {
        this.refreshEvent.emit();
      }
    });
  }
  onPaginateChange(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.limit = event.pageSize;
    this.refreshEvent.emit();
  }

  isExpired(expiryDate: string | Date | null): boolean {
    if (!expiryDate) {
      return false;
    }
    const today = new Date();
    const expiry = new Date(expiryDate);
    return expiry < today;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.payload.currentValue) {
      this.dataSource = changes.payload.currentValue;
    }
  }
}
