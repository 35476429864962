<form [formGroup]="groupSelectStep" class="third_step_accorddion training-task-form">
  <div class="training-task-container">
    <app-trial-banner
      *ngIf="trialStatus.isActive"
      [trialHeadline]="translatedText.license_books_trial_title"
      [trialText]="translatedText.license_task_trial_student_selection_description"
      [trialStatus]="trialStatus"
      [showExpirationDate]="false"
      [showButtons]="false"
    ></app-trial-banner>

    <ng-container *ngIf="isGroupLoading">
      <app-loading-spinner [id]="'fixed-height'"></app-loading-spinner>
    </ng-container>
    <ng-container *ngIf="!isGroupLoading"
      ><app-hint-box
        [title]="translatedText.training_task_create_hint_group_selection_title"
        [text]="translatedText.training_task_create_hint_group_selection"
        id="task-creation-group-selection-hint"
        icon="glasses"
      ></app-hint-box>
      <div class="search-wrapper">
        <mat-form-field class="search-teacher" floatLabel="never">
          <mat-label>{{ translatedText.admin_group_list_search }}</mat-label>
          <input
            matInput
            type="text"
            placeholder="{{ translatedText.admin_group_list_search }}"
            (input)="onSearchInputFilterChange($event)"
          /><button mat-mini-fab class="not-filled">
            <mat-icon svgIcon="streamline_outlined:search-2"></mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div
        *ngIf="groups?.length == 0 || (groups.length > 0 && filterGroupsWithoutPersonalData()?.length == 0)"
        class="empty_tasks_container"
      >
        <img src="../../../assets/empty.svg" alt="" />
        <p class="paragraph">
          <span *ngIf="userType == 'school_admin'">{{ translatedText.training_task_create_no_group }}</span>
          <span *ngIf="userType == 'teacher'">{{ translatedText.training_task_create_check_information }}</span>
        </p>
      </div>
      <div class="accordion_wrap diagnostic_according">
        <mat-accordion multi>
          <mat-expansion-panel
            [expanded]="groups.group.grade === 1 && (source === 'diagnostic' || autoMode) ? false : true"
            [togglePosition]="'after'"
            *ngFor="let groups of filterGroupsWithoutPersonalData()"
            [disabled]="
              (schoolYearTransition && nextYear != groups.group.school_year) ||
              (!schoolYearTransition && currentYear != groups.group.school_year) ||
              (groups.group.grade === 1 && (source === 'diagnostic' || autoMode))
            "
          >
            <mat-expansion-panel-header>
              <mat-panel-title
                class="round-checkbox"
                [ngClass]="{ 'not-laletu': environment.whitelabel.name != 'LaLeTu' }"
              >
                <mat-checkbox
                  (change)="toggleSelectAll(students, $event.checked)"
                  [checked]="students.selectedOptions.selected.length === groups.group._students.length"
                  [indeterminate]="
                    students.selectedOptions.selected.length < groups.group._students.length &&
                    students.selectedOptions.selected.length > 0
                  "
                  class="example-margin"
                  (click)="$event.stopPropagation()"
                  [disabled]="
                    disableSelectAll(groups.group) ||
                    (source != 'add-student' &&
                      ((schoolYearTransition && nextYear != groups.group.school_year) ||
                        (!schoolYearTransition && currentYear != groups.group.school_year) ||
                        (groups.group.grade === 1 && (source === 'diagnostic' || autoMode))))
                  "
                >
                </mat-checkbox>
                <div class="group-select">
                  <div>
                    <p class="paragraph paragraph--bold paragraph--xl">
                      {{ groups.group.name }} -
                      {{ groups.group?.school_year | slice: 2 }}
                    </p>
                  </div>
                  <span class="selected-students-count" *ngIf="students.selectedOptions.selected.length > 0">
                    <mat-icon svgIcon="streamline_filled:small-circle"></mat-icon>
                    {{ students.selectedOptions.selected.length }}
                  </span>

                  <p class="no-license paragraph" *ngIf="groups.group.grade === 1 && source === 'diagnostic'">
                    {{ translatedText.task_creation_diagnosis_first_graders_info }}
                  </p>
                </div>
              </mat-panel-title>
              <mat-panel-description>
                <div class="license-amount"></div>
              </mat-panel-description>
              <div class="tag-first-graders" *ngIf="groups.group.grade === 1 && autoMode">
                {{ translatedText.task_creation_automode_no_first_graders }}
              </div>
              <div
                class="tag-not-updated"
                *ngIf="
                  (schoolYearTransition && nextYear != groups.group.school_year) ||
                  (!schoolYearTransition && currentYear != groups.group.school_year)
                "
                [hidden]="groups.group.grade === 1 && autoMode"
              >
                <span *ngIf="groups.group.grade != 10">{{ translatedText.create_task_not_updated }}</span>
                <span *ngIf="groups.group.grade === 10">{{ translatedText.admin_group_list_max_class_reached }}</span>
              </div>
            </mat-expansion-panel-header>
            <mat-selection-list
              class="round-checkbox small"
              #students
              (selectionChange)="handleSelection($event, groupSelectStep.value)"
              formControlName="students"
            >
              <mat-list-option
                checkboxPosition="before"
                *ngFor="let student of groups.group._students; index as i"
                [value]="student"
                [selected]="checkForSelection(student, i)"
                [disabled]="
                  (schoolYearTransition && nextYear != groups.group.school_year) ||
                  (!schoolYearTransition && currentYear != groups.group.school_year) ||
                  (hasNoLicense(groups.group, student) && source != 'add-student') ||
                  hasTooManyTasks(groups.group, student) ||
                  student?.status == 'inactive' ||
                  (source === 'diagnostic' &&
                    (checkDiagnosticByLicenses(student?.diagnostic, student?.licenses) ||
                      isDiagnosticCompleted(student?.diagnostic))) ||
                  isStudentAlreadyInClass(student)
                "
              >
                <div class="list-item-element">
                  <div class="inline_text">
                    <img class="avatar_img--small" *ngIf="student?.avatarUrl" src="{{ student?.avatarUrl }}" />
                    <p class="paragraph username-with-avatar">
                      {{ student?.username }}
                    </p>
                  </div>

                  <!-- ALL: STUDENT DEACTIVATED CHIP -->
                  <p class="paragraph no-license" *ngIf="student?.status == 'inactive'">
                    {{ translatedText.training_task_create_inactive }}
                  </p>

                  <!-- ADD STUDENT: STUDENT ALREADY IN CLASS CHIP -->
                  <p class="paragraph no-license" *ngIf="isStudentAlreadyInClass(student)">
                    {{ translatedText.students_add_existing_already_in_class }}
                  </p>

                  <!-- DIAGNOSTIC AND TRAINING: NO LICENSES CHIP -->
                  <p
                    *ngIf="hasNoLicense(groups?.group, student) && source != 'add-student'"
                    class="paragraph no-license"
                  >
                    {{ translatedText.training_task_create_no_assigned_license }}
                  </p>

                  <!-- DIAGNOSTIC AND TRAINING: MAX NUMBER OF TASKS REACHED CHIP -->
                  <p *ngIf="hasTooManyTasks(groups.group, student)" class="paragraph no-license">
                    {{ translatedText.task_create_maximum_reached }}
                  </p>

                  <!-- DIAGNOSTIC: ALREADY CREATED TASK CHIP -->
                  <p
                    *ngIf="
                      source === 'diagnostic' &&
                      checkDiagnosticByLicenses(student?.diagnostic, student?.licenses) &&
                      !isDiagnosticCompleted(student?.diagnostic)
                    "
                    class="paragraph no-license"
                  >
                    {{ translatedText.task_detail_already_created }}
                  </p>

                  <!-- DIAGNOSTIC: ALREADY COMPLETED TASK CHIP -->
                  <div
                    class="tag-completed"
                    *ngIf="
                      source === 'diagnostic' &&
                      checkDiagnosticByLicenses(student?.diagnostic, student?.licenses) &&
                      isDiagnosticCompleted(student?.diagnostic)
                    "
                  >
                    <mat-icon svgIcon="streamline_outlined:success_tick_without_circle" class="icon-20"></mat-icon>
                    {{ translatedText.task_detail_completed }}
                  </div>
                </div>
              </mat-list-option>
            </mat-selection-list>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
  </div>
</form>
