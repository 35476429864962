<div>
  <!--  <app-license-activation-banner></app-license-activation-banner> -->

  <!--  <div class="row" *ngIf="!hideWidgets">
    <div class="col-md-3">
      <div class="dashboard_header_teacher_item">
        <mat-icon class="icon_circle" svgIcon="streamline_outlined:students"></mat-icon>
        <h2 class="h2" [countUp]="studentsCount">{{ studentsCount }}</h2>
        <p class="paragraph">{{ translatedText.dashboard_teacher_students }}</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="dashboard_header_teacher_item">
        <mat-icon class="icon_circle" svgIcon="streamline_outlined:classes"></mat-icon>
        <h2 class="h2" [countUp]="groupsCount">{{ groupsCount }}</h2>
        <p class="paragraph">{{ translatedText.dashboard_teacher_groups }}</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="dashboard_header_teacher_item">
        <mat-icon class="icon_circle_warn" svgIcon="streamline_outlined:unchecked-tasks"></mat-icon>
        <h2 class="h2" [countUp]="uncheckedTasks">{{ uncheckedTasks }}</h2>
        <p class="paragraph">{{ translatedText.dashboard_teacher_unsolved_tasks }}</p>
      </div>
    </div>
    <div class="col-md-3">
      <div class="dashboard_header_teacher_item">
        <mat-icon class="icon_circle" svgIcon="streamline_outlined:performance"></mat-icon>
        <h2 class="h2" [countUp]="averageReadingScore">{{ averageReadingScore }}</h2>
        <p class="paragraph">{{ translatedText.dashboard_teacher_average }}</p>
      </div>
    </div>
  </div> -->

  <div class="student_tasks">
    <div class="class_summary" [ngClass]="{ 'class_summary--row-above': !hideWidgets }">
      <div class="class_summary_header" *ngIf="!trialStatus.isActive">
        <div></div>
        <div>
          <mat-form-field class="dropdown-select-with-arrow">
            <mat-icon class="mat-select-arrow" svgIcon="streamline_outlined:mat-arrow-down"></mat-icon>
            <mat-label>{{ translatedText.admin_group_list_school_year_select }}</mat-label>
            <mat-select
              name="yearDetails"
              [(ngModel)]="selectedSchoolYear"
              (ngModelChange)="chooseSchoolYear($event)"
              [placeholder]="translatedText.admin_newyear_school_year_placeholder"
            >
              <mat-option *ngFor="let yearDetails of schoolYears" [value]="yearDetails.value">
                {{ translatedText.admin_group_list_filter_school_year + yearDetails.value.slice(2) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="no_records dashboard" [hidden]="groups.length > 0 || !emptyGroups">
        <img src="../../../assets/empty.svg" alt="" />
        <p class="paragraph paragraph--bold">
          <span *ngIf="userType === 'school_admin'">{{ translatedText.dashboard_teacher_no_classes }}</span>
          <span *ngIf="userType === 'teacher'">{{ translatedText.dashboard_teacher_no_classes_info }}</span>
        </p>
      </div>

      <div class="loading-container" *ngIf="isLoading">
        <app-loading-spinner></app-loading-spinner>
      </div>

      <app-group-widget [groups]="groups" [showError]="showError"></app-group-widget>
    </div>
  </div>
</div>
