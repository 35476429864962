import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from 'src/app/providers/translation.service';
import { map, Subject, takeUntil } from 'rxjs';
import {
  AdminService,
  ClassDetailsForm,
  GroupData,
} from 'src/app/providers/admin.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { map as lsmap } from 'lodash';
import { SuccessOverlayComponent } from 'src/app/helpers/success-overlay/success-overlay.component';
import { ClassDetailsComponent } from 'src/app/helpers/class-details/class-details.component';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  group?: any;
  step?: number;
}
@Component({
  selector: 'app-admin-new-school-year',
  templateUrl: './admin-new-school-year.component.html',
  styleUrls: ['./admin-new-school-year.component.scss'],
})
export class AdminNewSchoolYearComponent implements OnInit, OnDestroy {
  translatedText: any;
  language: string = '';
  isLoading: boolean = false;
  step: number = 1;
  chooseClassForm: FormGroup;
  studentListForm: FormGroup;
  page: number = 1;
  limit: number = 100;
  sortBy: string = 'name';
  sortDirection: number = 1;
  matSortDirection: string = 'asc';
  filterValues = {
    search: '',
  };
  groups: any = [];
  currentlyCheckedClass: string | null = null;
  isFormInvalid: boolean = false;
  selectedGroup: any;
  studentList: any;
  selectedStudentList: any = [];
  classDetailsStepData: ClassDetailsForm = {
    grade: '',
    stream: '',
    school_year: '',
    avatar: '',
    teachers: [],
  };
  isCurrentYearValidationRequired: boolean = true;
  currentSchoolYear: string | null = null;
  nextSchoolYear: string | null = null;
  saveCurrentSchoolYear: string | null = null;
  source: string | null = null;
  @ViewChild(ClassDetailsComponent)
  classDetailsComponent: ClassDetailsComponent;
  schoolYearTransition: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private translationService: TranslationService,
    private adminService: AdminService,
    private _formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    this.language = localStorage.getItem('language');
    if (injectedData && injectedData.hasOwnProperty('group')) {
      this.selectedGroup = injectedData['group'];
      this.step = 2;
      this.source = 'classlist';
    }
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.getSchoolYearUpdateInfo();
    this.getGroups();
    this.buildChooseClassForm();
    this.buildStudentListForm();
  }

  // Create Choose Class Form
  buildChooseClassForm() {
    this.chooseClassForm = this._formBuilder.group({
      class: ['', Validators.required],
    });
    if (this.selectedGroup) {
      this.chooseClassForm.patchValue({ class: this.selectedGroup._id });
      this.selectOneCheckBox(this.selectedGroup);
    }
  }

  // Create Student List Form
  buildStudentListForm() {
    this.studentListForm = this._formBuilder.group({
      students: ['', Validators.required],
    });
  }

  // Next Button Action
  next() {
    // If We are not updating anything in class details form, it wont trigger emit event
    if (
      this.step === 2 &&
      this.classDetailsStepData &&
      this.classDetailsStepData.grade === ''
    ) {
      this.classDetailsStepData = this.classDetailsComponent.classForm.value;
      this.classDetailsStepData.school_year = this.nextSchoolYear;
    } 
    // else if (
    //   this.step === 2 &&
    //   this.classDetailsStepData.school_year === this.currentSchoolYear
    // ) {
    //   this.snackBar.openFromComponent(ErrorSnackbarComponent, {
    //     panelClass: 'snack-error',
    //     data: this.translatedText?.admin_newyear_school_year_validation_msg,
    //     duration: 3000,
    //     horizontalPosition: 'left',
    //   });
    //   return false;
    // }

    if (this.step === 3) {
      this.updateClassFormSubmit();
    } else {
      this.step = this.step + 1;
    }
  }

  // Back Button Action
  back() {
    if (this.source === 'classlist' && this.step === 2) {
      return false;
    } else {
      this.step = this.step - 1;
    }

    if (this.step === 2 && this.classDetailsStepData.grade !== '') {
      this.selectedGroup.grade = Number(this.classDetailsStepData.grade) - 1;
      this.selectedGroup.stream = this.classDetailsStepData.stream;
    }

    if (this.step === 1) {
      this.chooseClassForm.reset();
      this.currentlyCheckedClass = this.selectedGroup._id;
      this.chooseClassForm.patchValue({ class: this.selectedGroup._id });
      this.cd.detectChanges();
      //this.chooseClassForm.patchValue({ class: this.selectedGroup._id });
    }
  }

  // Close Dialog Popup
  closeClassOverlay() {
    this.dialog.closeAll();
  }

  // After Succesfull API call, it will trigger success popup
  successOverlay() {
    this.closeClassOverlay();
    let groupAfter;
    let groupBefore: string =
      this.selectedGroup.grade +
      this.selectedGroup.stream +
      '-' +
      this.selectedGroup.school_year.slice(2);
    if (!this.schoolYearTransition) {
      groupAfter =
        this.classDetailsStepData.grade +
        this.classDetailsStepData.stream +
        '-' +
        this.saveCurrentSchoolYear;
    } else {
      groupAfter =
        this.classDetailsStepData.grade +
        this.classDetailsStepData.stream +
        '-' +
        this.classDetailsStepData.school_year;
    }
    let sucessMsg = this.translatedText.t('admin_reassigned_class_success', {
      groupBefore: groupBefore,
      groupAfter: groupAfter,
    });

    let dialogRef = this.dialog.open(SuccessOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'task-delete-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: '',
      data: {
        message: sucessMsg,
      },
    });
    dialogRef.afterClosed().subscribe(() => {});
  }

  /**
   * API call on adminService to retrieve requested teacher
   */
  getGroups() {
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .pipe(
        map((groupData: GroupData) => {
          console.log(groupData);
          // Filter Archieved classes
          this.groups = groupData.data.filter((item: any) => {
            return (
              !item.archived &&
              item.school_year !== this.nextSchoolYear &&
              item.grade !== 10
            );
          });
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  // Select Student CheckBox
  selectOneCheckBox(group: any) {
    this.currentlyCheckedClass = group._id;
    this.selectedGroup = group;
    this.studentList = this.selectedGroup._students.map((item: any) => {
      item.checked = false;
      return item;
    });
    this.chooseClassForm.patchValue({ class: group._id });
  }

  // Select All Students action
  selectAllStudents() {
    for (const student of this.studentList) {
      student.checked = true;
    }
    this.getSelectedStudents();
  }

  // Select Particular Student
  chooseStudent(item: any, index: number) {
    this.studentList[index].checked = !this.studentList[index].checked;
    this.getSelectedStudents();
  }

  // Get All the selected students to send API
  getSelectedStudents() {
    this.selectedStudentList = this.studentList.filter((student: any) => {
      return student.checked === true;
    });
  }

  // EventEmitter from Class Details Child comoponent
  classDetailsForm(event: any) {
    if (event) {
      const newClassDetails = JSON.parse(event);
      this.classDetailsStepData = newClassDetails.payload;
      this.isFormInvalid = newClassDetails.status === 'INVALID' ? true : false;
      this.classDetailsStepData.school_year = this.nextSchoolYear;
    }
  }

  // Update New Year Class API Call
  updateClassFormSubmit() {
    this.isLoading = true;
    this.getSelectedStudents();
    const studentIds = lsmap(this.selectedStudentList, '_id');
    const payload = {
      groupId: this.selectedGroup._id,
      studentIds: studentIds,
      teacherIds: this.classDetailsStepData.teachers,
      grade: this.classDetailsStepData.grade,
      stream: this.classDetailsStepData.stream,
      school_year: this.saveCurrentSchoolYear,
    };
    this.adminService
      .updateNewYearClass(payload)
      .pipe(
        map((result: any) => {
          this.isLoading = false;
          if (result && result.grade) {
            this.successOverlay();
          }
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  getSchoolYearUpdateInfo() {
    this.adminService.schoolYearChangeCheck().subscribe((data) => {
      console.log('school year stuff', data);
      const selectedYearStart = parseInt(this.selectedGroup.school_year.split('/')[0], 10);
      const nextYearStart = parseInt(data.nextYear.split('/')[0], 10);
      const yearDifference = nextYearStart - selectedYearStart;
    
      if (yearDifference === 2) {
        this.nextSchoolYear = data.year;
        this.currentSchoolYear = this.selectedGroup.school_year;
        this.saveCurrentSchoolYear = data.year;
      } else {
        this.saveCurrentSchoolYear = data.nextYear;
        this.nextSchoolYear = data.nextYear;
        this.currentSchoolYear = data.year;
        this.classDetailsStepData.school_year = this.nextSchoolYear;
      }
      if (data.yearChange) {
        this.schoolYearTransition = true;
      }
    });
  }

  // Angular Destory component
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.selectedGroup = null;
    this.selectedStudentList = [];
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
