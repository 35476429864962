import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import { SchoolsOnlyDialogComponent } from '../../helpers/schools-only/schools-only.component';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

@Component({
  selector: 'app-home-login',
  templateUrl: './home-login.component.html',
  styleUrls: ['./home-login.component.scss'],
})
export class HomeLoginComponent implements OnInit, OnDestroy {
  environment = environment;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translation) => (this.translatedText = translation),
      });
    this.openSchoolsOnlyInformation();
  }

  openSchoolsOnlyInformation() {
    const showSchoolOnlyDialog =
      localStorage.getItem('hide-schools-only-info') == 'closed' ? false : true;
    if (showSchoolOnlyDialog) {
      let dialogRef = this.dialog.open(SchoolsOnlyDialogComponent, {
        width: '100%',
        autoFocus: false,
        panelClass: 'schools-only-overlay-panel',
        // hasBackdrop: false,
        disableClose: true,
        backdropClass: 'schools-only-overlay-panel_backdrop',
        data: {},
      });
      // dialogRef.disableClose = true;
      dialogRef.afterClosed().subscribe((res) => {
        localStorage.setItem('hide-schools-only-info', 'closed');
      });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
