import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { LicenseTrialExtensionComponent } from 'src/app/license/license-trial-extension/license-trial-extension.component';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface DialogData {
  source: string;
}

export interface DialogData {}
@Component({
  selector: 'app-trial-banners',
  templateUrl: './trial-banners.component.html',
  styleUrls: ['./trial-banners.component.scss'],
})
export class TrialBannersComponent implements OnInit, OnDestroy {
  environment = environment;
  expiryDate: any = new Date();
  translatedText: any;
  source: string | null = null;
  language: string = '';
  upgradeDescription: string = '';
  isTrialExpired: boolean = false;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<TrialBannersComponent>,
    private authService: AuthService,
    private capacitorService: CapacitorService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    if (injectedData) {
      if (typeof injectedData['trialStatus'] !== 'undefined') {
        this.trialStatus = this.injectedData['trialStatus'];
      }
      if (typeof injectedData['source'] !== 'undefined') {
        this.source = this.injectedData['source'];
      }
    }
    this.language = localStorage.getItem('language');
    if (this.source === 'upgrade') {
      if (this.language === 'en') {
        this.upgradeDescription =
          'You can add only <strong>10 students</strong> in <strong>Trial Mode.</strong><br>' +
          '<strong>Upgrade your license</strong> to add more students.';
      } else if (this.language === 'de') {
        this.upgradeDescription =
          'Sie können maximal <strong>10 Lernende</strong> hinzufügen. ' +
          'Bitte erwerben Sie eine Lizenz um mehr Lernende hinzuzufügen.';
      }
    }
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.trialStatus = this.authService.getUserTrialStatus();
    this.isTrialExpired = this.trialStatus.expiryDate.getTime() < Date.now();
  }
  //not used
  openLicensetrialExtension() {
    let dialogRef = this.dialog.open(LicenseTrialExtensionComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'license-add-panel',
      disableClose: true,
      backdropClass: 'license-transfer-backdrop',
      data: {},
    });
    dialogRef.afterClosed().subscribe();
  }

  closeDialog() {
    if (this.trialStatus.expiryDate.getTime() < Date.now()) {
      this.dialogRef.close('trial-expired');
    } else {
      this.dialogRef.close('trial-welcome');
    }
  }

  getTrialCloseStatus() {
    return this.trialStatus.expiryDate.getTime() < Date.now()
      ? 'trial-expired'
      : 'trial-welcome';
  }

  buyKlett() {
    this.capacitorService.openUrl('https://www.klett.de/inhalt/lautlesetutor/produkte-&-lizenzen/272983');
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
