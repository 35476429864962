import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from 'src/app/material.module';
import { AccountDeleteComponent } from './account-delete.component';
@NgModule({
  declarations: [AccountDeleteComponent],
  imports: [BrowserModule, CommonModule, MaterialModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [AccountDeleteComponent],
})
export class AccountDeleteModule {}
