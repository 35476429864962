import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject, forkJoin, of } from 'rxjs';
import { takeUntil, filter, switchMap } from 'rxjs/operators';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { LicenseService } from 'src/app/providers/license.service';
import { TranslationService } from 'src/app/providers/translation.service';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-license-distribution',
  templateUrl: './license-distribution.component.html',
  styleUrls: ['./license-distribution.component.scss'],
})
export class LicenseDistributionComponent implements OnInit, OnDestroy {
  licenseForm: FormGroup;
  isLoading: boolean = false;
  template: string = 'release';
  studentId;
  avatar: any;
  username: string;
  studentLicenses = [];
  licensesToSchool = [];
  availableLicenses = {
    totalCountDiagnostic: 0,
    totalCountTraining: 0,
    totalCountLeftDiagnostic: 0,
    totalCountLeftTraining: 0,
  };
  translatedText: any;
  diChecked: boolean = false;
  foChecked: boolean = false;
  isNotReleaseLicense: boolean = false;
  licenseSuccess: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData, // TODO: private or public?
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['studentId'] !== 'undefined') {
        this.studentId = this.injectedData['studentId'];
      }
      if (typeof injectedData['template'] !== 'undefined') {
        this.template = this.injectedData['template'];
      }
      if (typeof injectedData['username'] !== 'undefined') {
        this.username = this.injectedData['username'];
      }
      if (typeof injectedData['studentLicenses'] !== 'undefined') {
        this.studentLicenses = this.injectedData['studentLicenses'];
      }
      if (typeof injectedData['availableLicenses'] !== 'undefined') {
        this.availableLicenses = this.injectedData['availableLicenses'];
      }
      if (typeof injectedData['licensesToSchool'] !== 'undefined') {
        this.licensesToSchool = this.injectedData['licensesToSchool'];
      }
      if (typeof injectedData['avatarUrl'] !== 'undefined') {
        this.avatar = this.injectedData['avatarUrl'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.isDiCheckboxDisabled();
    this.isFoCheckboxDisabled();
  }

  submitForm() {
    this.isLoading = true;
    this.isNotReleaseLicense = false;

    if (this.template === 'release') {
      const observables = [];

      if (this.diChecked) {
        const dataDI = {
          student_id: this.studentId,
          license_type: 'di',
        };

        observables.push(
          this.licenseService.releaseLicensesFromStudent(dataDI).pipe(
            filter((response) => !!response), // Ensure only truthy responses are considered
          ),
        );
      }

      if (this.foChecked) {
        const dataFO = {
          student_id: this.studentId,
          license_type: 'fo',
        };

        observables.push(
          this.licenseService.releaseLicensesFromStudent(dataFO).pipe(
            filter((response) => !!response), // Ensure only truthy responses are considered
          ),
        );
      }

      if (observables.length > 0) {
        forkJoin(observables).subscribe({
          next: (responses: any[]) => {
            this.isLoading = false;
            this.isNotReleaseLicense = true;
            if (Array.isArray(responses)) {
              const hasFalseOk = responses.some(
                (response) => response.ok === false,
              );
              if (hasFalseOk) {
                this.licenseSuccess = false;
              } else {
                this.licenseSuccess = true;
              }
            }
          },
          error: (error: any) => {
            this.isLoading = false;
            this.isNotReleaseLicense = false;
            this.dialog.closeAll();
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              panelClass: 'snack-error',
              data: this.translatedText?.technical_error,
              duration: 3000,
              horizontalPosition: 'left',
            });
          },
        });
      } else {
        // If neither diChecked nor foChecked, just reset the loading state
        this.isLoading = false;
        this.dialog.closeAll();
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      }
    }
    if (this.template == 'assign') {
      let students = [];
      students.push({
        student_id: this.studentId,
        diLicense:
          this.hasActiveLicense(this.studentLicenses, 'di') &&
          !this.checkLicenseAboutToExpire(this.studentLicenses, 'di')
            ? false
            : this.diChecked,
        foLicense:
          this.hasActiveLicense(this.studentLicenses, 'fo') &&
          !this.checkLicenseAboutToExpire(this.studentLicenses, 'fo')
            ? false
            : this.foChecked,
      });
      this.licenseService
        .distributeLicenseToStudents({
          students: students,
        })
        .subscribe({
          next: (response: any) => {
            this.isLoading = false;
            this.isNotReleaseLicense = true;

            if (response.ok) {
              this.licenseSuccess = true;
            } else {
              this.licenseSuccess = false;
            }
          },
          error: (error: any) => {
            this.isLoading = false;
            this.dialog.closeAll();
            this.snackBar.openFromComponent(ErrorSnackbarComponent, {
              panelClass: 'snack-error',
              data: this.translatedText?.technical_error,
              duration: 3000,
              horizontalPosition: 'left',
            });
          },
        });
    }
  }

  checkboxChecked() {
    if (this.template == 'assign') {
      let diChecked =
        this.hasActiveLicense(this.studentLicenses, 'di') &&
        !this.checkLicenseAboutToExpire(this.studentLicenses, 'di')
          ? false
          : this.diChecked;
      let foChecked =
        this.hasActiveLicense(this.studentLicenses, 'fo') &&
        !this.checkLicenseAboutToExpire(this.studentLicenses, 'fo')
          ? false
          : this.foChecked;
      return diChecked || foChecked;
    } else {
      // Release disable status
      return this.diChecked || this.foChecked;
    }
  }

  isDiCheckboxDisabled(): boolean {
    let status = this.hasActiveLicense(this.studentLicenses, 'di');
    if (this.template == 'assign') {
      let aboutToExpire = this.checkLicenseAboutToExpire(
        this.studentLicenses,
        'di',
      );
      // If license is about to expire soon do not preselect checkbox
      this.diChecked = aboutToExpire ? !aboutToExpire : status;
      return status;
    }
    return !status;
  }

  isFoCheckboxDisabled(): boolean {
    let status = this.hasActiveLicense(this.studentLicenses, 'fo');
    if (this.template == 'assign') {
      let aboutToExpire = this.checkLicenseAboutToExpire(
        this.studentLicenses,
        'fo',
      );
      // If license is about to expire soon do not preselect checkbox
      this.foChecked = aboutToExpire ? !aboutToExpire : status;
      return status;
    }
    return !status;
  }

  isSchoolLicense(type: string): boolean {
    if (type == 'di') {
      return this.licenseService.checkForUnlimitedDiagnosticLicense(
        type,
        this.licensesToSchool,
        true,
      );
    }
    return false;
  }

  // Function to handle changes to the checkbox
  onCheckboxChange(type: string, status: boolean) {
    if (this.template == 'assign') {
      if (type == 'di') {
        this.availableLicenses.totalCountLeftDiagnostic = status
          ? this.isSchoolLicense('di')
            ? this.availableLicenses.totalCountLeftDiagnostic
            : this.availableLicenses.totalCountLeftDiagnostic - 1
          : this.isSchoolLicense('di')
          ? this.availableLicenses.totalCountLeftDiagnostic
          : this.availableLicenses.totalCountLeftDiagnostic + 1;
      }
      if (type == 'fo') {
        this.availableLicenses.totalCountLeftTraining = status
          ? this.availableLicenses.totalCountLeftTraining - 1
          : this.availableLicenses.totalCountLeftTraining + 1;
      }
      console.log(this.diChecked);
      console.log(this.foChecked);
    }
    if (this.template == 'release') {
      // Check for private and school license
      if (!this.isSchoolLicense('di')) {
        if (this.hasActiveLicense(this.studentLicenses, 'di')) {
          if (type == 'di') {
            this.diChecked = status;
            this.availableLicenses.totalCountLeftDiagnostic = status
              ? this.availableLicenses.totalCountLeftDiagnostic + 1
              : this.availableLicenses.totalCountLeftDiagnostic - 1;
          }
        }
        if (this.hasActiveLicense(this.studentLicenses, 'fo')) {
          if (type == 'fo') {
            this.foChecked = status;
            this.availableLicenses.totalCountLeftTraining = status
              ? this.availableLicenses.totalCountLeftTraining + 1
              : this.availableLicenses.totalCountLeftTraining - 1;
          }
        }
      } else {
        if (type == 'di') {
          this.availableLicenses.totalCountLeftDiagnostic = status
            ? this.availableLicenses.totalCountLeftDiagnostic + 1
            : this.availableLicenses.totalCountLeftDiagnostic - 1;
        }
        if (type == 'fo') {
          this.availableLicenses.totalCountLeftTraining = status
            ? this.availableLicenses.totalCountLeftTraining + 1
            : this.availableLicenses.totalCountLeftTraining - 1;
        }
      }
    }
  }

  hasActiveLicense(licenses: any[], type: string): boolean {
    for (const license of licenses) {
      // Check if the current license has the specified type_key and active is true
      if (license.type_key === type && license.active) {
        return true;
      }
    }
    return false;
  }

  checkLicenseAboutToExpire(licenses: any[], type: string): boolean {
    const usedMasterLicenses: number[] = [];
    if (licenses) {
      const fourWeeksFromNow = new Date();
      fourWeeksFromNow.setDate(fourWeeksFromNow.getDate() + 28);
      let aboutToExpire = false;
      licenses = licenses.filter(
        (item) =>
          item.type_key == type &&
          new Date(item.expiration_date) <= fourWeeksFromNow,
      );
      if (licenses.length > 0) {
        aboutToExpire = true;
      }
      licenses.map((license) => usedMasterLicenses.push(license._master_id));
      return aboutToExpire
        ? this.licensesToSchool.some(
            (master) =>
              master.status === 'active' &&
              master.type_key == type &&
              new Date(master.expiration_date) > new Date() &&
              !usedMasterLicenses.includes(master._id),
          )
        : false;
    } else {
      return false;
    }
  }

  close(): void {
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
