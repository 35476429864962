<div class="transfer-teacher-wrapper" *ngIf="!isAccepted && !isSubmited && !isError">
  <div class="transfer-teacher_question-wrap" *ngIf="!isDeclined">
    <mat-icon svgIcon="streamline_outlined:school"></mat-icon>
    <h2 class="h2">{{
        translatedText.t('teacher_transfer_headline', {
          schoolName
        })
      }}</h2>
    <p class="paragraph paragraph--xl">{{translatedText.teacher_transfer_text}}</p>
    <div class="button_wrap">
      <button mat-flat-button color="secondary" [disabled]="isLoading" (click)="declineInvitation()" type="button"
        class="mat-flat-button--full" aria-label="cancel">
        {{ translatedText.teacher_transfer_decline }}
      </button>

      <button mat-flat-button color="primary" [disabled]="isLoading" (click)="showStep('accept')" type="button"
        class="mat-flat-button--full" aria-label="Connect">
        {{ translatedText.teacher_transfer_accept }}
      </button>
    </div>
  </div>

  <div class="transfer-teacher_question-wrap" *ngIf="isDeclined">
    <mat-icon svgIcon="streamline_outlined:prohibition"></mat-icon>
    <h2 class="h2">{{translatedText.teacher_transfer_decline_header}}</h2>
    <p class="paragraph paragraph--xl">{{translatedText.teacher_transfer_decline_text}}</p>
    <div class="button_wrap">
      <button mat-flat-button color="primary" (click)="showStep('close')" type="button" class="mat-flat-button--full"
        aria-label="Connect">
        {{ translatedText.teacher_transfer_decline_close }}
      </button>
    </div>
  </div>
</div>

<div class="transfer-teacher-selection-wrapper" *ngIf="isAccepted && !isError">
  <div class="transfer-teacher-selection-header">
    <div class=left_side></div>
    <div class="chip_outline">
      <div class="chip_content add-student-chip">{{ translatedText.teacher_transfer_data_chip }}</div>
    </div>
    <button mat-flat-button color="primary" [disabled]="!selectedOption || isLoading" (click)="submitTransfer()"
      type="button" class="mat-flat-button--full" aria-label="Connect">
      {{ translatedText.teacher_transfer_data_confirm }}
    </button>
  </div>
  <div class="transfer-teacher-selection-content">
    <h2 class="h2">{{translatedText.teacher_transfer_data_headline}}</h2>
    <p class="paragraph">{{translatedText.teacher_transfer_data_text}}</p>
    <mat-radio-group [(ngModel)]="selectedOption" aria-label="Select transfer option">
      <mat-radio-button class="custom-radio" value="1">{{ translatedText.teacher_transfer_data_all }}
        <ng-container *ngIf="selectedOption === '1'">
          <mat-icon>check</mat-icon>
        </ng-container>
      </mat-radio-button>
      <mat-radio-button class="custom-radio" value="2">{{ translatedText.teacher_transfer_data_group }} <ng-container
          *ngIf="selectedOption === '2'">
          <mat-icon>check</mat-icon>
        </ng-container>
      </mat-radio-button>
      <mat-radio-button class="custom-radio" value="3">{{ translatedText.teacher_transfer_data_nothing }} <ng-container
          *ngIf="selectedOption === '3'">
          <mat-icon>check</mat-icon>
        </ng-container>
      </mat-radio-button>
    </mat-radio-group>
  </div>
</div>

<div class="transfer-teacher-wrapper" *ngIf="isSubmited">
  <div class="transfer-teacher_question-wrap">
    <mat-icon svgIcon="streamline_outlined:check_circle"></mat-icon>
    <h2 class="h2">{{
        translatedText.t('teacher_transfer_success_header', {
          schoolName
        })
      }}</h2>
    <div class="button_wrap">
      <button mat-flat-button color="primary" (click)="showStep('success')" type="button" class="mat-flat-button--full"
        aria-label="Connect">
        {{ translatedText.teacher_transfer_decline_close }}
      </button>
    </div>
  </div>
</div>

<div class="transfer-teacher-wrapper" *ngIf="isError">
  <div class="transfer-teacher_question-wrap">
    <mat-icon svgIcon="streamline_outlined:prohibition"></mat-icon>
    <h2 class="h2">{{translatedText.admin_group_error}}</h2>
    <div class="button_wrap">
      <button mat-flat-button color="primary" (click)="showStep('close')" type="button" class="mat-flat-button--full"
        aria-label="Connect">
        {{ translatedText.teacher_transfer_decline_close }}
      </button>
    </div>
  </div>
</div>
