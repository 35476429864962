<div class="container-fluid static_container">
  <div class="for-parents-container">
    <div class="for-parents-header-wrap">
      <div class="title">
        <h1>Kontakt</h1>
        <h2>Bei allgemeinen Anfragen nutzen Sie das hier gezeigte Kontaktformular.</h2>
        <h4>
          Haben Sie eine technische Frage oder Probleme bei der Verwendung {{ translatedText.contact_from_product }}?
          <br />
          In unserem Helpcenter finden Sie die Antworten auf viele Fragen.
        </h4>
        <h4>
          Für alle weiteren Fragen hilft unser Helpdesk Ihnen gern. Senden Sie uns gerne eine E-Mail, nutzen den gelben
          "Hilfe" Button am linken Bildschirmrand oder rufen Sie uns einfach an.
          <br /><br />
          E-Mail:
          <a href="{{ replaceDomainName('mailto:helpdesk@laletu.de') }}">{{
            replaceDomainName('helpdesk@laletu.de')
          }}</a>
          Telefon:
          <a href="tel:+4969380299333">+49 (0)69 380 299 333</a>
          (Montag bis Freitag - 9:30 Uhr bis 16:30 Uhr)
        </h4>
      </div>
      <div class="for-parents-form-bg">
        <div class="for-parents-form-wrapper">
          <form [formGroup]="personalForm" #formDirective="ngForm">
            <div class="input-wrapper">
              <div class="">
                <div class="col-md-12 col-sm-12 input-flex">
                  <mat-form-field>
                    <input matInput formControlName="firstname" placeholder="Vorname" type="text" />
                    <mat-error *ngIf="personalForm.get('firstname')?.hasError('required')">
                      Bitte tragen Sie einen Namen ein
                    </mat-error>
                    <mat-error *ngIf="personalForm.get('firstname').hasError('hasDigits')">
                      Es sind keine Zahlen erlaubt.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput formControlName="lastname" placeholder="Nachname" type="text" />
                    <mat-error *ngIf="personalForm.get('lastname')?.hasError('required')">
                      Bitte tragen Sie einen Namen ein
                    </mat-error>
                    <mat-error *ngIf="personalForm.get('lastname').hasError('hasDigits')">
                      Es sind keine Zahlen erlaubt.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-12 col-sm-12 input-flex">
                  <mat-form-field>
                    <input matInput formControlName="email" type="text" placeholder="E-Mail Adresse" />
                    <mat-error
                      *ngIf="personalForm.get('email')?.hasError('required') && personalForm.get('email').touched"
                    >
                      Bitte tragen Sie eine E-Mail ein.
                    </mat-error>
                    <mat-error
                      *ngIf="personalForm.get('email').hasError('invalidEmail') && personalForm.get('email').touched"
                    >
                      Bitte tragen Sie eine gültige E-Mail ein.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput formControlName="phoneNum" type="text" placeholder="Telefonnummer" />
                    <mat-error
                      *ngIf="personalForm.get('phoneNum')?.hasError('required') && personalForm.get('phoneNum').touched"
                    >
                      Bitte tragen Sie eine Telefonnummer ein.
                    </mat-error>
                    <mat-error
                      *ngIf="
                        personalForm.get('phoneNum').hasError('invalidPhone') && personalForm.get('phoneNum').touched
                      "
                    >
                      Es sind keine Buchstaben erlaubt.
                    </mat-error>
                  </mat-form-field>
                </div>

                <div class="col-md-12 col-sm-12">
                  <mat-form-field>
                    <textarea matInput formControlName="message" type="message" placeholder="Nachricht"></textarea>
                    <mat-error *ngIf="personalForm.get('message')"></mat-error>
                  </mat-form-field>

                  <mat-form-field class="mat-form-field--honey">
                    <input matInput formControlName="honey" type="text" />
                    <!-- <mat-error *ngIf="personalForm.get('honeypot')"></mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="btn-wrapper">
              <button
                mat-flat-button
                color="primary"
                class="home-primary_student for-parents-form-btn"
                type="submit"
                (click)="submitForm()"
                [disabled]="!privacyCkeckbox.checked || personalForm.invalid"
              >
                Absenden
                <mat-icon class="material-icons">navigate_next</mat-icon>
              </button>
            </div>
            <mat-checkbox #privacyCkeckbox class="checkbox">
              Ich bin einverstanden, dass ich unter der angegebenen E-Mail-Adresse gemäß den
              <a [routerLink]="['/datenschutz']" target="_blank">Datenschutzhinweisen</a> zu Recherchen und Umfragen
              kontaktiert werden darf.
            </mat-checkbox>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
