<div class="dashboard-license-truecontainer">
  <div class="teacher-content-container">
    <div class="">
      <h1 class="h3 h3--bold">{{ translatedText.dashboard_license_title }}</h1>
      <div class="dashboard-license-tabs-wrap" [@.disabled]="true">
        <div class="table-wrapper toggle-tabs">
          <mat-tab-group
            #tasksTabs
            (selectedTabChange)="tabChanged($event)"
            [(selectedIndex)]="selectedTabIndex"
            [disableRipple]="true"
          >
            <mat-tab label="{{ translatedText.dashboard_license_available_licenses }}">
              <div class="teacher-list-filter-wrap">
                <div class="teacher-list-filter-selection">
                  <!-- <mat-form-field>
                                    <mat-label>{{translatedText.dashboard_license_group}}</mat-label>
                                    <mat-select [formControl]="filterFormGroup.get('license')" panelClass="dashboard-task-select" disableOptionCentering multiple>
                                    <mat-select-trigger>
                                        {{generateSelectionText(license, filterFormGroup.get('license').value)}}
                                    </mat-select-trigger>
                                    <app-select-all-option [control]="filterFormGroup.get('license')" [values]="license" [valuetype]="'object'"></app-select-all-option>
                                    <mat-option *ngFor="let license of license" [value]="license?.value">
                                        {{license.viewValue}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                    
                                <mat-form-field>
                                    <mat-label>{{translatedText.dashboard_license_status}}</mat-label>
                                    <mat-select [formControl]="filterFormGroup.get('status')" panelClass="dashboard-task-select" disableOptionCentering multiple>
                                    <mat-select-trigger>
                                        {{generateSelectionText(status, filterFormGroup.get('status').value)}}
                                    </mat-select-trigger>
                                    <app-select-all-option [control]="filterFormGroup.get('status')" [values]="status" [valuetype]="'object'"></app-select-all-option>
                                    <mat-option *ngFor="let status of status" [value]="status?.value">
                                        {{status.viewValue}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                </div>

                <!-- <mat-form-field class="full-width search-teacher" floatLabel="never">
                                <mat-label>Suche</mat-label>
                                <input matInput type="text" (input)="onSearchInputFilterChange($event)" placeholder="S{{translatedText.dashboard_license_search}}">
                            </mat-form-field> -->
              </div>

              <div class="table-container two-sticky-left-columns">
                <table
                  mat-table
                  [dataSource]="dataSource?.data"
                  multiTemplateDataRows
                  class="mat-elevation-z8"
                  matSort
                  matSortDisableClear
                  [matSortActive]="sortBy"
                  [matSortDirection]="matSortDirection"
                  (matSortChange)="onSortChange($event)"
                >
                  <ng-container matColumnDef="name" sticky>
                    <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translatedText.dashboard_license_student }}
                    </th>
                    <td mat-cell mat-cell *matCellDef="let element">
                      <p class="paragraph paragraph--s">{{ element.name }}</p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="teacher">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header>
                      {{ translatedText.dashboard_license_teacher }}
                    </th>
                    <td mat-cell mat-cell *matCellDef="let element">
                      <ng-container *ngIf="element?._teacher?.length > 0">
                        <div class="table_teacher" *ngFor="let teacher of element?._teacher">
                          <div class="teacher_with_icon teacher_with_icon--blue">
                            <button mat-mini-fab>
                              <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                            </button>
                            <p class="paragraph paragraph--s">
                              {{ teacher?.firstname }} {{ teacher?.lastname }}
                              <span *ngIf="teacher?._user.role == 'school_{{translatedText.dashboard_license_admin}}'"
                                >(A)</span
                              >
                            </p>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="element?._teacher?.length == 0">
                        <div class="teacher_with_icon empty">
                          <mat-icon class="warn-icon" svgIcon="streamline_outlined:warning-circle"></mat-icon>
                          <p class="paragraph paragraph--s disable-color">
                            {{ translatedText.dashboard_license_teacher_no }}
                            {{ translatedText.dashboard_license_teacher }}
                          </p>
                        </div>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="test_license">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_diagnostic }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <p
                        class="paragraph paragraph--s"
                        matTooltip="{{
                          generateLicenseInformation(element?.licenses, 'di', element?.diagnose_blocked_licenses)
                        }}"
                      >
                        {{
                          generateLicenseAmountLeft(element?.licenses, 'di', 'left', element?.diagnose_blocked_licenses)
                        }}
                        /
                        {{ getLicenseAmount(element?.licenses, 'di', 'total') }}
                      </p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="training_license">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_training }}
                    </th>
                    <td mat-cell mat-cell *matCellDef="let element">
                      <p
                        class="paragraph paragraph--s"
                        matTooltip="{{
                          generateLicenseInformation(element?.licenses, 'fo', element?.child_tasks_blocked_minutes)
                        }}"
                      >
                        {{
                          generateLicenseAmountLeft(
                            element?.licenses,
                            'fo',
                            'left',
                            element?.child_tasks_blocked_minutes
                          )
                        }}
                        /
                        {{ getLicenseAmount(element?.licenses, 'fo', 'total') }}
                      </p>
                    </td>
                  </ng-container>

                  <!-- <ng-container matColumnDef="type3_license">
                                            <th mat-header-cell mat-header-cell *matHeaderCellDef>Type #3 License</th>
                                            <td mat-cell mat-cell *matCellDef="let element"><p class="paragraph paragraph--s"></p></td>
                                        </ng-container> -->

                  <ng-container matColumnDef="action" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_action }}
                    </th>
                    <td mat-cell mat-cell *matCellDef="let element">
                      <div class="license-action-wrap"></div>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                      <button
                        mat-mini-fab
                        class="not-filled expand"
                        aria-label="expand row"
                        (click)="
                          expandedElement = expandedElement === element ? null : element; $event.stopPropagation()
                        "
                      >
                        <mat-icon *ngIf="expandedElement !== element">navigate_next</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">expand_more</mat-icon>
                      </button>
                    </td>
                  </ng-container>

                  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                  <ng-container matColumnDef="expandedDetail">
                    <td
                      mat-cell
                      *matCellDef="let element"
                      class="nested_table_td"
                      [attr.colspan]="displayedColumns.length + 1"
                    >
                      <table
                        mat-table
                        [dataSource]="element?._students"
                        class="example-element-detail nested-table"
                        [ngClass]="element == expandedElement ? 'expanded' : 'collapsed'"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"
                      >
                        <!-- Define the columns for the nested table -->
                        <ng-container matColumnDef="placeholder">
                          <th mat-header-cell mat-header-cell *matHeaderCellDef class="hidden_nested_header">12</th>
                          <td mat-cell *matCellDef="let nestedElement"></td>
                        </ng-container>
                        <ng-container matColumnDef="username">
                          <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                            {{ translatedText.dashboard_license_students }}
                          </th>
                          <td mat-cell *matCellDef="let nestedElement">
                            <span class="paragraph paragraph--bold username-with-avatar">
                              <span><img *ngIf="nestedElement?.avatarUrl" src="{{ nestedElement?.avatarUrl }}" /></span
                              >{{ nestedElement?.username }}
                            </span>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="teacher">
                          <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                            {{ translatedText.dashboard_license_teacher }}
                          </th>
                          <td mat-cell *matCellDef="let nestedElement">
                            <p class="paragraph paragraph--s">
                              {{ nestedElement?._teacher?.lastname }}
                              {{ nestedElement?._teacher?.firstname }}
                            </p>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="test_license">
                          <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                            {{ translatedText.admin_group_list_table_header_diagnostic }}
                          </th>
                          <td mat-cell *matCellDef="let nestedElement">
                            <p class="paragraph paragraph--s"></p>
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="training_license">
                          <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                            {{ translatedText.admin_group_list_table_header_training }}
                          </th>
                          <td mat-cell *matCellDef="let nestedElement">
                            <p class="paragraph paragraph--s"></p>
                          </td>
                        </ng-container>
                        <!-- <ng-container matColumnDef="type3_license">
                                                <th mat-header-cell mat-header-cell *matHeaderCellDef class="hidden_nested_header">Type #3 License</th>
                                                <td mat-cell mat-cell *matCellDef="let nestedElement"><p class="paragraph paragraph--s"></p></td>
                                            </ng-container> -->

                        <ng-container matColumnDef="action">
                          <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
                            {{ translatedText.dashboard_license_action }}
                          </th>
                          <td mat-cell *matCellDef="let nestedElement" class="no-shadow">
                            <div class="license-action-wrap">
                              <!-- <button mat-icon-button type="button" class="license-accept" matSuffix>
                                <mat-icon svgIcon="streamline_outlined:eye"></mat-icon>
                            </button> -->
                            </div>
                          </td>
                        </ng-container>
                        <!-- Replace the below line with your desired nested table columns -->
                        <!-- Add more ng-container elements for additional columns -->

                        <!-- Define the nested table rows -->
                        <!-- <tr class="hidden_nested_tr" mat-header-row *matHeaderRowDef="nestedColumns"></tr> -->
                        <tr mat-row *matRowDef="let nestedElement; columns: nestedColumns"></tr>
                      </table>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
                  <tr
                    mat-row
                    *matRowDef="let element; columns: columnsToDisplayWithExpand"
                    class="example-element-row"
                    [class.example-expanded-row]="expandedElement === element"
                    (click)="expandedElement = expandedElement === element ? null : element"
                  ></tr>
                  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
                </table>
              </div>
              <div class="paginator-container">
                <mat-paginator
                  [hidePageSize]="true"
                  [length]="dataSource?.meta.totalItems"
                  [pageSize]="
                    dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
                      ? dataSource?.meta.itemsPerPage
                      : dataSource?.meta.totalItems
                  "
                  [pageSizeOptions]="[10, 25, 100]"
                  (page)="pageEvent = $event; onPaginateChange($event)"
                  showFirstLastButtons
                >
                </mat-paginator>
              </div>
            </mat-tab>

            <mat-tab label="{{ translatedText.dashboard_license_license_requests }}">
              <div class="table-container license-table">
                <table
                  mat-table
                  multiTemplateDataRows
                  [dataSource]="requestDataSource"
                  class="mat-elevation-z8"
                  matSort
                  matSortDisableClear
                  [matSortActive]="sortBy"
                  [matSortDirection]="matSortDirection"
                  (matSortChange)="onSortChange($event)"
                >
                  <tr mat-header-row *matHeaderRowDef="requestColumns"></tr>
                  <tr mat-row *matRowDef="let element; columns: requestColumns"></tr>

                  <ng-container matColumnDef="group" sticky>
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_group_name }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="element?._group != null">
                        <p class="paragraph paragraph--s">
                          {{ element?._group?.name }}
                          {{ element?._group?.school_year | slice : 2 }}
                        </p>
                      </ng-container>
                      <ng-container *ngIf="element._group == null">
                        <p class="paragraph paragraph--s">
                          {{ getGroupName(element?._student?._group)?.name }}
                          {{ getGroupName(element?._student?._group)?.school_year | slice : 2 }}
                        </p>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_amount_of_licenses }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <p class="paragraph paragraph--s">{{ element.amount }}</p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_license_type }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="element.type_key == 'fo'">
                        <p class="paragraph paragraph--s">
                          {{ translatedText.dashboard_license_training }}
                        </p>
                      </ng-container>
                      <ng-container *ngIf="element.type_key == 'di'">
                        <p class="paragraph paragraph--s">
                          {{ translatedText.dashboard_license_diagnostic }}
                        </p>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_created }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <p class="paragraph paragraph--s">
                        {{ element.created | date : 'd MMM y HH:mm' }}
                      </p>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_status_license }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="wider-column">
                      <ng-container *ngIf="element.status == 'requested'">
                        <p class="paragraph paragraph--s request-chip request-chip--requested">
                          {{ translatedText.dashboard_license_status_request }}
                        </p>
                      </ng-container>
                      <ng-container *ngIf="element.status == 'decline'">
                        <p class="paragraph paragraph--s request-chip request-chip--decline">
                          {{ translatedText.dashboard_license_status_denied }}
                        </p>
                      </ng-container>
                      <ng-container *ngIf="element.status == 'accept'">
                        <p class="paragraph paragraph--s request-chip request-chip--accept">
                          {{ translatedText.dashboard_license_status_accepted }}
                        </p>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="admin" stickyEnd>
                    <th mat-header-cell *matHeaderCellDef>
                      {{ translatedText.dashboard_license_admin }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="element?._reviser">
                        <div class="teacher_with_icon teacher_with_icon--blue">
                          <button mat-mini-fab>
                            <mat-icon svgIcon="streamline_filled:user"></mat-icon>
                          </button>
                          <p class="paragraph paragraph--s">
                            {{ element?._reviser }}
                          </p>
                        </div>
                      </ng-container>
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="noRecord">
                    <mat-footer-cell *matFooterCellDef colspan="2">
                      <div class="no_records" [hidden]="requestDataSource?.length > 0">
                        <img src="../../../assets/empty.svg" alt="" />
                        <p class="paragraph paragraph--bold paragraph--xl">
                          {{ translatedText.dashboard_license_no_license_requests }}
                        </p>
                      </div>
                    </mat-footer-cell>
                  </ng-container>
                </table>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
</div>
