<div class="schools-info-dialog">
  <div class="visible-tablet-or-larger">
    <div class="schools-info-dialog-content">
      <mat-icon svgIcon="streamline_outlined:schools_acadamic_big" class="icon-132"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.schools_only_info_title }}</h3>
      <p class="paragraph" [innerHTML]="schoolsOnlyInfoText"></p>
    </div>

    <div class="schools-dialog-content--btns mt-5">
      <button mat-flat-button color="primary" (click)="closeDialog()">
        {{ translatedText.schools_only_info_btn }}
      </button>
    </div>
  </div>
</div>
