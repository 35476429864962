import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  @ViewChild('stepper') stepper: CdkStepper;
  environment = environment;
  rForm: FormGroup;
  selectedIndex: number;
  userRole;
  isloading: boolean = false;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private location: Location,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    private translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    console.log(this.translatedText);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translation) => (this.translatedText = translation),
      });

    this.userRole = this.route.snapshot.params['role'];

    // Form init
    this.rForm = this._formBuilder.group({
      email: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  navigateBack(): void {
    this.location.back();
  }

  maskEmail(email: string): string {
    if (!email) {
      return '';
    }

    const parts = email.split('@');
    if (!parts) {
      return '';
    }
    const username = parts[0];
    const domain = parts[1];

    const maskedUsername =
      username?.charAt(0) + '*'.repeat(username?.length - 1);
    const maskedEmail = maskedUsername + '@' + domain;

    return maskedEmail;
  }

  navigateToLogin(): void {
    this.router.navigate(['/login/teacher']);
  }

  navigateToDashboard(): void {
    this.router.navigate(['admin-dashboard']);
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    this.isloading = true;
    this.authService.resetPassword(form.value.email, this.translatedText).subscribe({
      next: (res) => {
        this.stepper.selectedIndex = 1;
        this.isloading = false;
      },
      error: (error) => {
        this.isloading = false;
        if (error.error.message === 'email input failed - reset password') {
          let message = 'Diese E-Mail-Adresse existiert nicht';
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            panelClass: 'snack-error',
            data: message,
            duration: 3000,
            horizontalPosition: 'left',
          });
        }
      },
    });
  }
}
