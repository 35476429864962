import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
} from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { BookData, BookService } from '../../providers/book.service';
import { LicenseService } from '../../providers/license.service';
import { BookPreviewComponent } from '../book-preview/book-preview.component';
import { BooksFilterComponent } from '../books-filter/books-filter.component';

export interface DialogData {
  name: string;
  _id: string;
  url: string;
}

export interface Book {
  _id?: string;
  title?: string;
  file?: File;
  type?: string;
  size?: string;
  originalname?: string;
  path?: string;
}

@Component({
  selector: 'app-book-list',
  templateUrl: './book-list.component.html',
  styleUrls: ['./book-list.component.scss'],
})
export class BookListComponent implements OnInit, OnDestroy {
  @Input('contentType') contentType: 'klett' | 'full' | 'trial' = 'full';
  @Input() taskType: string;
  @Output() bookListData = new EventEmitter<any>();
  //@Output() bookListClose = new EventEmitter<boolean>();
  @Output() isTimeChoice = new EventEmitter<boolean>(false);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name'];
  dataSource: any;
  dialogConfig: any;
  books: any;
  categories: any;
  userType;
  userAge = 0;
  isAdmin = false;
  searchInputText: string = '';
  quizIsVisible = false;
  id;
  screenHeight: number;
  screenWidth: number;
  pageSize = 4;
  page = 1;
  limit = 1000;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    grade: [],
    age: [],
    difficulty: [],
    categories: [],
    publisher: [],
  };
  difficultyOptions: any[] = [
    { value: 1, viewValue: '1' },
    { value: 2, viewValue: '2' },
    { value: 3, viewValue: '3' },
    { value: 4, viewValue: '4' },
    { value: 5, viewValue: '5' },
  ];
  ageOptions: any[] = [];
  gradeOptions: any[] = [];
  categoryOptions: any[] = [];
  publisherOptions: any[] = [];
  filterFormGroup: FormGroup;
  isLoading: boolean = true;
  selectedClass: boolean = false;
  disableBookSelection: boolean = false;
  filterCategories;
  //   BOOKSTORE_ACTIVE = environment.bookstoreModule;
  readingTime;
  taskTitle;
  isTask: boolean = false;
  licenseActive: boolean = false;
  taskId;
  userTaskUuid;
  selectedGrade = '';
  //selectedDifficulty = '';
  selectedCategories = '';
  singleGroup: boolean = false;
  groupGrade;
  firstBookLoad: boolean = false;
  translatedText: any;
  diffFieldEnabled: boolean = false;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  selectedIsbn: string = '';
  dataforFilters;
  selectedFilters: any = undefined;
  anyBookSelection: boolean = false;
  numberOfSelectedFilters: number = 0;
  collapsedFilters: boolean = false;

  private searchInputEvent = new Subject<any>();
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public resDialog: MatDialogRef<BookListComponent>,
    private route: ActivatedRoute,
    private authService: AuthService,
    private bookService: BookService,
    private licenseService: LicenseService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['type'] !== 'undefined') {
        this.taskType = this.injectedData['type'];
      }
      if (typeof injectedData['time'] !== 'undefined') {
        this.readingTime = this.injectedData['time'];
      }
      if (typeof injectedData['taskId'] !== 'undefined') {
        this.taskId = this.injectedData['taskId'];
      }
      if (typeof injectedData['userTaskUuid'] !== 'undefined') {
        this.userTaskUuid = this.injectedData['userTaskUuid'];
      }
      if (typeof injectedData['groupGrade'] !== 'undefined') {
        this.groupGrade = this.injectedData['groupGrade'];
        if (this.groupGrade) {
          this.singleGroup = true;
          this.selectedGrade = this.groupGrade.toString();
        }
      }
      if (typeof injectedData['contentType'] !== 'undefined') {
        this.contentType = this.injectedData['contentType'];
      }
    }
  }

  ngOnInit() {
    this.setTranslations(this.route.snapshot.firstChild.data.translation);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslations(translatedText);
      });

    // Define filter group
    this.filterFormGroup = new FormGroup({
      grade: new FormControl(),
      age: new FormControl(),
      categories: new FormControl(),
      difficulty: new FormControl(),
      publisher: new FormControl(),
    });
    // Check license before starting task
    this.licenseService
      .checkLicense()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this.licenseActive = true;
      });
    this.searchInputEvent
      .pipe(
        map((event) => event.target.value),
        filter(
          (filterValue) => filterValue.length == 0 || filterValue.length > 2,
        ),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        this.filterValues.search = value;
        this.page = 1;
        if (this.paginator != undefined) {
          this.paginator.pageIndex = 0;
        }
        // Check for search results
        this.getBookList();
      });
    if (this.taskType == 'time' || this.taskType == 'self') {
      this.isTask = true;
    }
    // Init datasource
    this.getBookList();

    //Disables difficulty field until grade is selected
    //this.filterFormGroup.controls['difficulty'].disable();

    this.trialStatus = this.authService.getUserTrialStatus();
  }

  setTranslations(translatedText: any) {
    this.translatedText = translatedText;
  }

  replaceUmlautsAndLowercaseAndPrefix(str) {
    if (str) {
      return str
        .replace(/ü/g, 'ue')
        .replace(/ö/g, 'oe')
        .replace(/Ü/g, 'Ue')
        .replace(/ä/g, 'ae')
        .replace(/ß/g, 'ss')
        .toLowerCase();
    } else {
      // Handle the case where the translation is undefined
      return 'book_preferences_undefined';
    }
  }

  openBookFilters() {
    let dialogRef = this.dialog.open(BooksFilterComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'book-list-filter-panel',
      disableClose: true,
      backdropClass: 'book-list-filter-backdrop',
      data: {
        allFilters: {
          age: this.ageOptions,
          grade: this.gradeOptions,
          categories: this.categoryOptions,
        },
        firstBookLoad: this.firstBookLoad,
        numberOfSelectedFilters: this.numberOfSelectedFilters,
        selectedFilters: this.selectedFilters,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res != 'close') {
        this.selectedFilters = res;
        this.firstBookLoad = false;
        this.onSelectChange(this.selectedFilters);
      } else {
        return;
      }

      this.numberOfSelectedFilters =
        this.selectedFilters['age'].length +
        this.selectedFilters['categories'].length +
        this.selectedFilters['grade'].length;
    });
  }

  resetFilters(): void {
    this.selectedFilters['age'] = [];
    this.selectedFilters['categories'] = [];
    this.selectedFilters['grade'] = [];
    this.onSelectChange(this.selectedFilters);
    this.numberOfSelectedFilters = 0;
  }

  getBookList() {
    this.isLoading = true;
    this.limit = 1000;
    this.bookService
      .getBookList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
        this.taskId,
        this.contentType,
      )
      .pipe(
        map((bookData: BookData) => {
          this.dataSource = bookData;

          if (!this.firstBookLoad) {
            bookData.data.forEach((book) => {
              const ageValue = book['_book'].lower_age_barrier;
              const lowerGradeBarrier = book['_meta'].lower_grade_barrier;
              //const difficultyLevel =  book['_contenthub'].lower_grade_barrier;

              if (ageValue !== undefined && ageValue >= 7) {
                const ageViewValue = this.translatedText.t(
                  'book_list_ageViewValue',
                  { age: ageValue },
                );
                if (
                  !this.ageOptions.some((option) => option.value === ageValue)
                ) {
                  this.ageOptions.push({
                    value: ageValue,
                    viewValue: ageViewValue,
                  });
                }
              }

              if (lowerGradeBarrier !== undefined) {
                const lowerGradeBarrierValue =
                  this.translatedText.book_list_lowerGradeBarrierValue +
                  ' ' +
                  lowerGradeBarrier;

                if (
                  !this.gradeOptions.some(
                    (option) => option.value === lowerGradeBarrier,
                  )
                ) {
                  this.gradeOptions.push({
                    value: lowerGradeBarrier,
                    viewValue: lowerGradeBarrierValue,
                  });
                }
              }
            });
            if (!this.ageOptions.some((option) => option.value === 15)) {
              this.ageOptions.push({
                value: 15,
                viewValue: this.translatedText.t('book_list_ageViewValue', {
                  age: '15',
                }),
              });
            }
            // Sortieren Sie ageOptions nach den lower_age_barrier-Werten
            this.ageOptions.sort((a, b) => a.value - b.value);
            this.gradeOptions.sort((a, b) => a.value - b.value);
            this.firstBookLoad = true;
          }

          // Move Übungsbücher to top
          const firstEntry = 'Übungsbücher';
          if (bookData['categories'].includes(firstEntry)) {
            const tempCategories = bookData['categories'].filter(
              (item) => item !== firstEntry,
            );
            const finalCategories = bookData['categories'].includes(firstEntry)
              ? [firstEntry].concat(tempCategories)
              : tempCategories;
            this.categories = finalCategories;
          } else {
            this.categories = bookData['categories'];
          }
          this.filterCategories = this.categories;
          if (this.filterValues.categories.length == 0) {
            this.categoryOptions = bookData['categories'].map((item) => {
              return {
                value: item,
                viewValue:
                  this.translatedText[
                    'book_preferences_' +
                      this.replaceUmlautsAndLowercaseAndPrefix(item)
                  ],
              };
            });
          }
          if (this.filterValues.publisher.length == 0) {
            this.publisherOptions = bookData['publisher'].map((item) => {
              return {
                value: item.name,
                viewValue: item.name,
              };
            });

            let k = this.publisherOptions.findIndex(
              (e) => e.viewValue == 'Klett',
            );
            let firstOfList = this.publisherOptions.splice(k, 1);
            this.publisherOptions.sort((a, b) => {
              return a.viewValue.toLowerCase() > b.viewValue.toLowerCase()
                ? 1
                : -1;
            });
            this.publisherOptions = firstOfList.concat(this.publisherOptions);
          }
          this.isLoading = false;
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  generateSelectionText(obj, selection) {
    let returnText = '';
    if (selection != undefined && selection.length > 0) {
      if (typeof obj[0] === 'object') {
        let found = obj.filter((item) => item.value == selection[0]);
        returnText = found[0].viewValue;
      } else {
        returnText = selection[0];
      }
      if (selection.length > 1) {
        let additionText =
          selection.length === 2
            ? this.translatedText?.filter_more_single
            : this.translatedText?.filter_more;
        returnText += ' + ' + (selection.length - 1) + ' ' + additionText;
      }
      if (obj.length === selection.length) {
        returnText = this.translatedText?.filter_all;
      }
    }
    return returnText;
  }

  onSelectChange(filters) {
    if (filters != undefined) {
      this.filterValues['grade'] = filters.grade;
      this.filterValues['age'] = filters.age;
      this.filterValues['categories'] = filters.categories;
    } else {
      return;
    }
    //
    /*  if (filter === 'grade') {
      if (event.value.length != 0) {
        this.filterFormGroup.controls['difficulty'].enable();
        this.diffFieldEnabled = true;
      } else {
        this.filterFormGroup.controls['difficulty'].disable();
        this.filterFormGroup.controls['difficulty'].setValue('');
        this.diffFieldEnabled = false;
      }
    } */
    // Load books
    this.getBookList();
  }

  categoryCheck(category) {
    let count = this.dataSource.data.filter((book) =>
      book?._meta?.categories.includes(category),
    ).length;
    return count > 0 ? true : false;
  }

  categoryFilter(category) {
    return this.dataSource.data.filter((book) =>
      book?._meta?.categories.includes(category),
    );
  }

  /*  difficultyFilter(difficulty) {
    return this.dataSource.data.filter((book) =>
      book?._contenthub?.book_complexity.complexity.includes(difficulty),
    );
  }
 */
  bookCheck(books) {
    return books?._value;
  }

  onSearchInputFilterChange(event) {
    this.searchInputEvent.next(event);
  }

  openBookPreview(isbn, title, description, author): void {
    let dialogRef = this.dialog.open(BookPreviewComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-overlay-panel_backdrop',
      data: {
        isbn: isbn,
        title: title,
        description: description,
        author: author,
      },
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        if (res.path == 'book-preview') {
          let data = {
            res: res,
            path: 'book-list',
          };
          this.resDialog.close(data);
        }
      });
  }

  openBookPreviewTask(isbn, title, description, author): void {
    let taskData = {
      isbn: isbn,
      title: title,
      description: description,
      author: author,
      type: this.taskType,
      taskId: this.taskId,
      userTaskUuid: this.userTaskUuid,
    };
    if (this.taskType == 'time') {
      taskData['time'] = this.readingTime;
    }
    let dialogRef = this.dialog.open(BookPreviewComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-overlay-panel-backdrop',
      data: taskData,
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        console.log(res);
        if (res?.path == 'book-preview') {
          let data = {
            res: res,
            path: 'book-list',
          };
          this.resDialog.close(data);
        }
        if (res == 'taskStarted') {
          this.resDialog.close();
        }
        // this.resDialog.close(res);
      });
  }

  setBook(isbn, title, description, author, dsef_version) {
    let bookData = {
      isbn: isbn,
      title: title,
      description: description,
      author: author,
      listview: false,
      preview: true,
      dsef_version: dsef_version,
    };
    this.selectedIsbn = isbn;
    this.anyBookSelection = false;
    this.bookListData.emit(bookData);
    this.isTimeChoice.emit(false);
  }

  studentChoosesBook(event): void {
    this.selectedIsbn = '';
    this.anyBookSelection = true;
    this.isTimeChoice.emit(true);
  }

  getBookCoverByIsbn(isbn, size) {
    return this.bookService.getBookCoverByIsbn(isbn, size);
  }

  handleImageError(event: any, book) {
    // just try once
    if (!book.imageLoadFailed) {
      book.imageLoadFailed = true;
      event.target.src = book.imageUrl;
    }
  }

  resetSearch() {
    this.searchInputText = '';
    this.filterValues.search = '';
    this.getBookList();
  }

  ngOnDestroy() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
