<div class="home-trial-login-dialog">
  <button type="button" class="close-button close-button--right" mat-mini-fab matSuffix (click)="closeDialog()">
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>

  <div class="visible-tablet-or-larger">
    <div class="home-trial-dialog-content">
      <mat-icon svgIcon="streamline_outlined:trial_progress_big" class="icon-132"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.license_books_trial_title }}</h3>
      <p *ngIf="data.upgradeModalText === 'maxGroupsReached'">
        {{ translatedText.trial_mode_groups }}
      </p>
      <p *ngIf="data.upgradeModalText === 'maxStudentsReached'">
        {{ translatedText.trial_mode_students }}
      </p>
      <p *ngIf="data.upgradeModalText === 'maxTeachersReached'">
        {{ translatedText.trial_mode_teacher }}
      </p>
      <p *ngIf="data.upgradeModalText === 'task'">
        {{ translatedText.trial_mode_books }}
      </p>
      <p *ngIf="data.upgradeModalText === 'readingMinutes'">
        {{ translatedText.trial_mode_reading_minutes }}
      </p>
    </div>

    <div class="home-login-dialog-content--btns mt-5">
      <button mat-flat-button color="secondary" type="button" routerLinkActive="active-link" (click)="closeDialog()">
        {{ translatedText.admin_group_create_cancel }}
      </button>
      <!-- TODO: Klett shop link -->
      <button *ngIf="!environment.whitelabel.hide_klett_shop" mat-flat-button color="primary" routerLinkActive="active-link" (click)="openUrl('https://www.klett.de/lehrwerk/lautlesetutor-ausgabe-ab-2023/produktuebersicht')">
        {{ translatedText.license_trial_shop }}
      </button>
    </div>
  </div>
</div>
