import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map } from 'rxjs';
import { environment } from 'src/environments/environment';

interface VersionInfo {
  version: string;
  hotfix: string;
  buildDate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private localVersionUrl = '/assets/version.json'; // Local version file
  private remoteVersionUrl; // Remote version file

  constructor(private http: HttpClient) {
    switch (environment.sentryEnvironment) {
      case 'prod':
        this.remoteVersionUrl = 'https://www.laletu.de/assets/version.json';
        break;
      case 'demo':
        this.remoteVersionUrl = 'https://demo.laletu.de/assets/version.json';
        break;
      case 'staging':
        this.remoteVersionUrl =
          'https://www.evolution.staging.laletu.de/assets/version.json';
        break;
      case 'sandbox':
        this.remoteVersionUrl =
          'https://www.evolution.sandbox.laletu.de/assets/version.json';
        break;
      default: // dev
        this.remoteVersionUrl =
          'https://www.evolution.dev.laletu.de/assets/version.json';
        break;
    }
  }

  // Fetch both local and remote version files
  compareVersions(): Observable<{ local: VersionInfo; remote: VersionInfo }> {
    const localVersion$ = this.http.get<VersionInfo>(this.localVersionUrl);
    const remoteVersion$ = this.http.get<VersionInfo>(
      `${this.remoteVersionUrl}?timestamp=${new Date().getTime()}`,
    );
    return forkJoin({ local: localVersion$, remote: remoteVersion$ });
  }

  // Compare the versions and hotfix values
  isUpdateRequired(local: VersionInfo, remote: VersionInfo): boolean {
    // Compare the version using semantic versioning logic
    if (local.version !== remote.version) {
      return true;
    }
    // console.log('hotfix', local.hotfix < remote.hotfix);
    // If versions are the same, compare the hotfix
    return local.hotfix < remote.hotfix;
  }
}
