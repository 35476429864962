import {
  Component,
  OnInit,
  ViewChild,
  Inject,
  Optional,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AdminService, GroupData } from 'src/app/providers/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import {
  UntypedFormBuilder,
  ValidatorFn,
  FormControl,
  FormGroup,
  Validators,
  FormGroupDirective,
  AbstractControl,
  FormArray,
  FormArrayName,
} from '@angular/forms';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { CdkStepper } from '@angular/cdk/stepper';
import { TeacherService } from 'src/app/providers/teacher.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from 'src/app/providers/translation.service';
import { AddTeacherSuccessComponent } from './add-teacher-success/add-teacher-success.component';
import { GroupService } from 'src/app/providers/group.service';
import {
  UpgradeDialogComponent,
  UpgradeDialogData,
} from 'src/app/trial-phase/upgrade-banner/upgrade-banner.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';

export interface DialogData {}

/**
 * Teacher create form
 */
@Component({
  selector: 'app-admin-teacher-create',
  templateUrl: './admin-teacher-create.component.html',
  styleUrls: ['./admin-teacher-create.component.scss'],
})
export class AdminTeacherCreateComponent implements OnInit {
  rForm: FormGroup;
  userTypes: string[];
  filteredItems: Observable<any[]>;
  newSalutationItem: FormControl;
  salutation;
  isClosedDialog: boolean = false;
  showAddButton: boolean = false;
  selectedIndex: number;
  formStep: FormGroup;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  isWebmail: boolean = false;
  isPhone: boolean = false;
  infoEmailText: string =
    'Dieser Dienst darf nur von Lehrkräften im Rahmen Ihrer Lehrtätigkeit und zur Evaluation des Dienstes genutzt werden. \n\n Bitte nutzen Sie für die Registrierung Ihre dienstliche E-Mail-Adresse. Sollten Sie über keine entsprechende E-Mail-Adresse verfügen und eine Webmail-Adresse (@gmail, @aol, @web, etc.) nutzen wollen, werden wir vor Gewährung eines Zugangs ggf. Ihre Identität überprüfen. Geben Sie hierzu bitte auch Ihre Telefonnummer an.';
  isLoading: boolean = false;
  page = 1;
  limit = -1;
  sortBy = 'name';
  sortDirection = 1;
  filterValue = '';
  groups;
  lastStep;
  isCreated: boolean = false;
  @ViewChild('stepper') stepper: CdkStepper;
  teacherId;
  schoolId;
  roleText: string = '';
  roleTextMultiple: string = '';
  createAdmin;
  selectedRole: string = 'teacher';
  buttonText: string = '';
  translatedText;
  trialStatus: { isActive: boolean; expiryDate: Date } = {
    isActive: false,
    expiryDate: null,
  };
  teachersArray: any = [];
  totalTeachers: number = 1;
  schoolYear;
  selectedGroups: any = [];
  disabledToolTip: boolean;
  emailInput;
  emailExistsWarnings: { [index: number]: boolean } = {};
  invitationExistsWarnings: { [index: number]: boolean } = {};
  sameSchoolWarnings: { [index: number]: boolean } = {};
  isNotTrialWarnings: { [index: number]: boolean } = {};
  teacherEmail: string;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AdminTeacherCreateComponent>,
    private teacherService: TeacherService,
    public snackBar: MatSnackBar,
    @Optional() @Inject(MAT_DIALOG_DATA) public injectedData: DialogData,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private groupService: GroupService,
  ) {
    if (injectedData) {
      if (typeof injectedData['createAdmin'] !== 'undefined') {
        this.createAdmin = this.injectedData['createAdmin'];
      }
    }
  }

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.setTranslations();
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        this.setTranslations();
      });

    this.trialStatus = this.authService.getUserTrialStatus();
    // get teacher to get school id
    this.teacherId = this.authService.getTeacherId();
    this.teacherService
      .readSingleTeacher(this.teacherId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.schoolId = res['_school'];
        this.teacherEmail = res['email'];
      });
    // Get user types for adding roles menu item
    this.userTypes = this.authService.getHierachicalRoles();
    this.getGroups();
    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);

    this.rForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator]],
      position: [this.selectedRole, Validators.required],
      phone: ['', [Validators.pattern('[- +()0-9]+')]],
      groups: [],
      teachers: this._formBuilder.array([]),
    });
    this.cdr.detectChanges();
    this.addTeachers(this.totalTeachers);
  }

  addTeachers(numberOfTeachers) {
    const teacherForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      email: ['', [Validators.required, this.customEmailValidator]],
      position: [this.selectedRole, Validators.required],
      groups: [],
    });
    this.teachersFormControl.push(teacherForm);

    this.totalTeachers = this.teachersFormControl.length;
    numberOfTeachers = this.totalTeachers;

    this.trialStatus = this.authService.getUserTrialStatus();
    this.authService.getCurrentTrialStatus().subscribe({
      next: (trialStatus) => {
        this.trialStatus = trialStatus;
        console.log('Admin dashboard status', this.trialStatus);
      },
    });
  }

  //makes form data into an array
  get teachersFormControl() {
    return <FormArray>this.rForm.get('teachers');
  }

  removeTeachers(index: number) {
    if (this.totalTeachers > 1) {
      this.teachersFormControl.controls.splice(index, 1);
      this.totalTeachers = this.teachersFormControl.length;
    }
  }

  setTranslations() {
    if (this.createAdmin === true) {
      this.selectedRole = 'school_admin';
      this.buttonText = this.translatedText.admin_teacher_create_admin;
    } else if (this.createAdmin == false) {
      this.selectedRole = 'teacher';
      this.buttonText =
        this.translatedText.admin_teacher_create_position_teacher;
    } else {
      this.selectedRole = 'teacher';
      this.buttonText =
        this.translatedText.admin_teacher_create_position_teacher;
    }
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  customEmailValidator(
    control: AbstractControl,
  ): { [key: string]: any } | null {
    const emailRegex =
      /^\s*([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,})\s*$/;
    if (control.value && !emailRegex.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }
    return results;
  }

  addTeacherSuccess() {
    let dialogRef = this.dialog.open(AddTeacherSuccessComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'add-teacher-success-panel',
      disableClose: true,
      backdropClass: 'create-training-task-panel-backdrop',
    });

    dialogRef.afterClosed().subscribe({
      next: (res) => {},
    });
  }

  //reset form and navigate to first step - unused after redesign
  resetForm({ skipTrialDialog } = { skipTrialDialog: false }) {
    if (this.trialStatus.isActive && !skipTrialDialog) {
      this.dialog.open<UpgradeDialogComponent, UpgradeDialogData>(
        UpgradeDialogComponent,
        {
          width: '85vw',
          autoFocus: false,
          disableClose: true,
          panelClass: 'home-trial-dialog-panel',
          // hasBackdrop: false,
          backdropClass: 'license-add-backdrop',
          data: { upgradeModalText: 'maxTeachersReached' },
        },
      );
      return;
    }

    this.isCreated = false;
    this.rForm.reset();
    this.rForm.get('position').setValue('teacher');
    this.markFormAsUntouched();
    this.stepper.selectedIndex = 0;
  }

  markFormAsUntouched() {
    Object.keys(this.rForm.controls).forEach((key) => {
      this.rForm.get(key).markAsUntouched();
    });
  }

  getSelectedGroup(group: object) {
    if (!this.selectedGroups.includes(group)) {
      this.selectedGroups.push(group);
    }
  }

  formatSelectedGroups(i): string {
    if (!this.teachersFormControl.value[i].groups) return '';
    let groupsNames = [];
    for (let tg of this.teachersFormControl.value[i].groups) {
      for (let g of this.groups) {
        if (g._id === tg) {
          groupsNames.push(g.name);
        }
      }
    }
    return groupsNames.join(', ');
  }

  hasMaxTeachers(numberOfTeachers: number) {
    if (numberOfTeachers === 3) {
      //console.log(numberOfTeachers);
      return (this.disabledToolTip = true);
    }
  }

  // Verify Each Teacher EMail id
  checkEmailExists(index: number) {
    const selectedRowEmail =
      this.teachersFormControl.controls[index].value.email;
    if (selectedRowEmail && selectedRowEmail !== '') {
      this.checkEmailValidationWithInList(index, selectedRowEmail);
      this.isLoading = true;
      this.authService
        .checkEmailAvailabiltyAsAdmin(selectedRowEmail)
        .subscribe({
          next: (result: any) => {
            if (
              result.available &&
              !result.invitation &&
              !result.sameSchool &&
              !result.trial
            ) {
              this.resetWarningsAndErrors(index);
              this.emailExistsWarnings[index] = true;
              this.checkEmailValidationWithInList(index, selectedRowEmail);
            } else if (
              result.available &&
              result.invitation &&
              !result.sameSchool &&
              !result.trial
            ) {
              this.setWarningsAndErrors(index, {
                invitationWarning: true,
                invalidForm: true,
              });
            } else if (
              result.available &&
              !result.invitation &&
              result.sameSchool &&
              !result.trial
            ) {
              this.setWarningsAndErrors(index, {
                sameSchoolWarning: true,
                invalidForm: true,
              });
            } else if (
              result.available &&
              !result.invitation &&
              !result.sameSchool &&
              result.trial
            ) {
              this.setWarningsAndErrors(index, {
                trialWarning: true,
                invalidForm: true,
              });
            } else {
              this.resetWarningsAndErrors(index);
              this.checkEmailValidationWithInList(index, selectedRowEmail);
            }
          },
          error: (err: any) => {
            this.teachersFormControl.controls[index].setErrors({
              invalidForm: true,
            });
            this.teachersFormControl.controls[index]
              .get('email')
              ?.setErrors({ invalid: true });
            this.isLoading = false;
          },
        });
    }
  }
  private resetWarningsAndErrors(index: number): void {
    this.emailExistsWarnings[index] = false;
    this.invitationExistsWarnings[index] = false;
    this.sameSchoolWarnings[index] = false;
    this.isNotTrialWarnings[index] = false;
    this.teachersFormControl.controls[index].setErrors(null);
    this.teachersFormControl.controls[index].get('email')?.setErrors(null);
    this.isLoading = false;
  }

  private setWarningsAndErrors(
    index: number,
    options: {
      emailWarning?: boolean;
      invitationWarning?: boolean;
      sameSchoolWarning?: boolean;
      trialWarning?: boolean;
      invalidForm?: boolean;
    },
  ): void {
    this.emailExistsWarnings[index] = options.emailWarning || false;
    this.invitationExistsWarnings[index] = options.invitationWarning || false;
    this.sameSchoolWarnings[index] = options.sameSchoolWarning || false;
    this.isNotTrialWarnings[index] = options.trialWarning || false;
    if (options.invalidForm) {
      this.teachersFormControl.controls[index].setErrors({ invalidForm: true });
      this.teachersFormControl.controls[index]
        .get('email')
        ?.setErrors({ invalid: true });
    }
  }

  // Check Front end validation with exists list
  checkEmailValidationWithInList(index: number, selectedEmail: string) {
    for (var i = 0; i < this.teachersFormControl.controls.length; i++) {
      if (i !== index) {
        if (
          this.teachersFormControl.controls[i].value.email === selectedEmail
        ) {
          this.teachersFormControl.controls[index].setErrors({
            invalidForm: true,
          });
          this.teachersFormControl.controls[index]
            .get('email')
            ?.setErrors({ invalid: true });
          break;
        }
      }
    }
  }

  createTeachers(teachersData) {
    this.adminService
      .createTeachers(teachersData, this.translatedText, this.trialStatus)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.getGroups();
          //adding teachers to selected groups
          const teacherToAddToGroups = [];
          teachersData.map((teacherAdded) => {
            if (teacherAdded.groups && teacherAdded.groups.length > 0) {
              res.user.map((data) => {
                if (data.email === teacherAdded.email) {
                  const indexOfUserFound = res.user.indexOf(data);
                  console.log('index', indexOfUserFound);
                  teacherToAddToGroups.push({
                    teacherId: res.teacher[indexOfUserFound]._id,
                    groupsId: teacherAdded.groups,
                  });
                }
              });
            }
          });
          console.log('teachers added', teacherToAddToGroups);
          this.addTeacherToGroups(teacherToAddToGroups);
        },
        (error: any) => {
          this.isLoading = false;
          console.log(error);
          if (error.error.message == 'E-Mail bereits vergeben') {
            this.snackBar.open(
              this.translatedText?.admin_teacher_create_email_error_used,
              '',
              {
                panelClass: 'snack-error',
                duration: 3000,
                horizontalPosition: 'right',
              },
            );
          }
          if (error.error.message == 'username taken') {
          } else {
          }
        },
      );
  }

  addTeacherToGroups(formData): void {
    formData.forEach((teacher) => {
      const data = {
        teacherId: teacher.teacherId,
        groupIds: teacher.groupsId,
      };
      this.teacherService.addTeacherToGroups(data).subscribe({
        next: (res) => {
          this.dialog.closeAll();
          this.snackBar.openFromComponent(SuccessSnackbarComponent, {
            panelClass: 'snack-success',
            data: this.translatedText.teachers_were_added,
            duration: 3000,
            horizontalPosition: 'left',
          });
        },
        error: (error) => {
          //this.isLoading = false;
          console.error(error);
          this.snackBar.open(
            this.translatedText?.admin_teacher_managing_error,
            '',
            {
              panelClass: 'snack-error ',
              duration: 3000,
              horizontalPosition: 'left',
            },
          );
        },
      });
    });
  }
  submitForm() {
    if (this.teachersFormControl.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;
    this.isCreated = true;
    this.createTeachers(this.teachersFormControl.value);
    this.roleText = this.translatedText?.admin_teacher_create_send_teacher;
    this.roleTextMultiple = this.translatedText?.admin_teacher_create_teachers;
    this.addTeacherSuccess();
  }
  closeTeacherOverlay(): void {
    // Reset form
    this.resetForm({ skipTrialDialog: true });
    this.dialog.closeAll();
  }

  // API call on adminService to retrieve requested teacher
  getGroups() {
    this.adminService
      .getGroupList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValue,
      )
      .pipe(
        map((groupData: GroupData) => {
          //console.log(groupData);
          this.groups = groupData.data;
        }),
      )
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
  }

  //get active step from stepper
  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.totalTeachers != undefined) {
      if (changes.totalTeachers.currentValue > 0) {
        setTimeout(() => {
          this.addTeachers(changes.totalTeachers.currentValue);
        }, 500);
      }
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
