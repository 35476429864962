import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { LanguagesCSVComponent } from './languages-csv.component';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';

@NgModule({
  declarations: [LanguagesCSVComponent],
  imports: [BrowserModule, MaterialModule, LoadingSpinnerModule],
  providers: [],
  schemas: [],
  bootstrap: [],
  exports: [LanguagesCSVComponent],
})
export class LanguagesCSVModule {}
