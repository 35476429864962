<div class="app_confirmation_wrapper">
  <div class="header">
    <h1 mat-dialog-title>{{ data.title }}</h1>
  </div>

  <mat-dialog-content *ngIf="data.note">
    <p [innerHTML]="data.note"></p>
  </mat-dialog-content>

  <mat-dialog-actions class="button_wrap">
    <button mat-flat-button color="primary" (click)="apply()" *ngIf="data.accept">
      <mat-icon *ngIf="data.acceptIcon">{{ data.acceptIcon }}</mat-icon>
      <ng-container *ngIf="data.acceptText">{{ data.acceptText }}</ng-container>
    </button>
    <button mat-flat-button color="secondary" (click)="cancel()" *ngIf="data.decline">
      <mat-icon *ngIf="data.declineIcon">{{ data.declineIcon }}</mat-icon>
      <ng-container *ngIf="data.declineText">{{ data.declineText }}</ng-container>
    </button>
  </mat-dialog-actions>
</div>
