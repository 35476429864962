<div class="student-add-container wider">
  <h1>{{ title }}</h1>

  <div class="top-bar">
    <div class="top-bar-counters">
      <div class="licenses-wrap students">
        <p>
          <mat-icon svgIcon="streamline_outlined:user"></mat-icon>
          {{ translatedText.task_detail_students }}
        </p>
        <p>
          <strong>{{ totalStudents }}</strong>
        </p>
      </div>
      <div class="licenses-wrap diagnosis">
        <p>
          <mat-icon class="pink-icon" svgIcon="streamline_outlined:target"></mat-icon>
          {{ translatedText.students_add_total_diagnostic }}
        </p>
        <p>
          <strong>
            <span *ngIf="!diagnosticTotalCountLicenses">{{ diagnosticLeftCount }}</span>
            <span *ngIf="diagnosticTotalCountLicenses">∞</span>
          </strong>
        </p>
      </div>

      <div class="licenses-wrap training">
        <p>
          <mat-icon class="green-icon" svgIcon="streamline_outlined:lightning"></mat-icon>
          {{ translatedText.students_add_total_training }}
        </p>
        <p>
          <strong>
            <span>{{ trainingLeftCount }}</span>
          </strong>
        </p>
      </div>
    </div>
    <div [matTooltip]="checkMaxStudentCount() ? translatedText.students_add_max_num_reached : ''"></div>
    <button
      mat-flat-button
      color="unset"
      (click)="addNewStudent()"
      class="add-another-block student"
      [ngClass]="{ disabled: checkMaxStudentCount() }"
      [matTooltip]="checkMaxStudentCount() ? translatedText.students_add_max_num_reached : ''"
    >
      <span class="add_learner_btn">
        <mat-icon class="add-icon" svgIcon="streamline_filled:add_plus_v2">add</mat-icon>
        <p>{{ translatedText.students_add_more }}</p>
      </span>
    </button>
  </div>
  <form [formGroup]="studentFormStep" #formDirective="ngForm">
    <div formArrayName="group">
      <div
        *ngFor="let student of getStudentFormControls(); let i = index"
        class="add-teacher-form"
        [ngClass]="allFilled(i)"
      >
        <ng-container [formGroupName]="i">
          <div class="animate-pop">
            <p class="blue-circle">
              {{ i + 1 }}
            </p>
            <div class="row">
              <div class="col-4">
                <mat-label
                  ><span
                    >{{ translatedText.students_add_fullname }}*
                    <small>{{ translatedText.students_add_wont_save_name }}</small></span
                  ></mat-label
                >
                <mat-form-field>
                  <input
                    matInput
                    formControlName="name"
                    type="text"
                    placeholder="{{ translatedText.students_add_fullname }}"
                    name="fullname"
                    id="fullname"
                  />
                  <mat-error *ngIf="student.get('name').hasError('required')">
                    {{ translatedText.students_add_name_error }}
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- native language select -->
              <div class="col-3">
                <mat-label>{{ translatedText.students_add_language }}*</mat-label>
                <mat-form-field>
                  <input
                    type="text"
                    placeholder="{{ translatedText.students_add_language }}"
                    aria-label="Muttersprache"
                    matInput
                    formControlName="language"
                    [matAutocomplete]="auto"
                    appAutocompletePosition="trigger"
                    required
                  />
                  <mat-autocomplete
                    #auto="matAutocomplete"
                    [displayWith]="displayFn.bind(this)"
                    (optionSelected)="setLanguageOption($event?.option?.value, i)"
                  >
                    <mat-option *ngFor="let language of filteredOptions[i] | async" [value]="language?.code">{{
                      getLanguageByCode(language?.code)
                    }}</mat-option>
                  </mat-autocomplete>
                  <mat-error
                    *ngIf="
                      student.get('language').hasError('incorrect') &&
                      student.get('language').touched &&
                      !student.get('language').hasError('required')
                    "
                  >
                    {{ translatedText.students_add_language_error }}
                  </mat-error>
                  <mat-error *ngIf="student.get('language').hasError('required')">
                    {{ translatedText.students_add_language_selection }}
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- month and year of birth select -->
              <div class="col-3">
                <mat-label>{{ translatedText.students_add_birthdate }}*</mat-label>
                <mat-form-field>
                  <input
                    matInput
                    [max]="maxBirthday"
                    formControlName="age"
                    type="text"
                    (click)="birthdaypicker.open()"
                    [matDatepicker]="birthdaypicker"
                    required
                  />
                  <mat-datepicker-toggle matSuffix [for]="birthdaypicker"></mat-datepicker-toggle>
                  <mat-datepicker
                    #birthdaypicker
                    startView="multi-year"
                    (monthSelected)="setMonthAndYear($event, birthdaypicker, i)"
                  ></mat-datepicker>
                  <!-- <mat-error *ngIf="studentFormStep.get('age').hasError('required')">Please choose a birth year and month.</mat-error>  -->
                </mat-form-field>
              </div>
              <!-- gender select -->
              <div class="col-2">
                <mat-label>{{ translatedText.students_add_gender }}*</mat-label>
                <mat-form-field>
                  <mat-select
                    formControlName="gender"
                    aria-label="Select an option"
                    [value]="student.gender"
                    disableOptionCentering
                    panelClass="larger-select"
                  >
                    <mat-option (change)="genderSelection()" value="male">
                      <mat-icon class="male-icon" svgIcon="streamline_outlined:male"></mat-icon>
                      {{ translatedText.students_add_gender_male }}
                    </mat-option>
                    <mat-option (change)="genderSelection()" value="female">
                      <mat-icon class="female-icon" svgIcon="streamline_outlined:female"></mat-icon>
                      {{ translatedText.students_add_gender_female }}
                    </mat-option>
                    <mat-option (change)="genderSelection()" value="divers">
                      <mat-icon class="divers-icon" svgIcon="streamline_outlined:divers"></mat-icon>
                      {{ translatedText.students_add_gender_divers }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <button class="delete-button" mat-fab (click)="removeStudent(i)" [disabled]="totalStudents === 1">
                <mat-icon svgIcon="streamline_outlined:delete-outline"></mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </form>
</div>
