import { ContentObserver } from '@angular/cdk/observers';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Console } from 'console';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export interface DialogData {
  source: string;
}

export interface DialogData {}
@Component({
  selector: 'app-invitation-delete',
  templateUrl: './invitation-delete.component.html',
  styleUrls: ['./invitation-delete.component.scss'],
})
export class InvitationDeleteComponent implements OnInit, OnDestroy {
  environment = environment;
  translatedText: any;
  firstname: string = '';
  lastname: string = '';
  isLoading: boolean = false;
  isError: boolean = false;
  teacherId: string = '';
  isSubmited: boolean = false;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<InvitationDeleteComponent>,
    private authService: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    if (injectedData) {
      if (typeof injectedData['lastname'] !== 'undefined') {
        this.lastname = this.injectedData['lastname'];
      }
      if (typeof injectedData['firstname'] !== 'undefined') {
        this.firstname = this.injectedData['firstname'];
      }
      if (typeof injectedData['teacherId'] !== 'undefined') {
        this.teacherId = this.injectedData['teacherId'];
      }
    }
  }

  ngOnInit(): void {
    console.log(this.teacherId);
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  deleteInvatation() {
    this.isLoading = true;
    this.authService.deleteInvitation(this.teacherId).subscribe({
      next: (res) => {
        this.isLoading = false;
        this.isSubmited = true;
      },
      error: (error: any) => {
        this.isError = true;
        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
