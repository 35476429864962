<div class="trial_notification collapse-animation" [ngClass]="{ 'hidden-banner': closeBanner ? true : null }">
  <button mat-mini-fab type="button" class="banner-close-button" [class]="isExpiredTrial() ? 'expired': ''"
    (click)="closeBanner = !closeBanner" matSuffix
    *ngIf="id === 'book-list-component' || 'diagnostic-create-component'">
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="notification_box" [class]="isExpiredTrial() ? 'trial-banner expiry-mode' : 'trial-mode'">
    <div class="row align-items-center">
      <div [class]="showButtons && !environment.whitelabel.hide_klett_shop ? 'col-md-7' : 'col-md-12'">
        <div class="tn-heading">
          <mat-icon *ngIf="!isExpiredTrial()" svgIcon="streamline_outlined:trial_progress"></mat-icon>
          <mat-icon *ngIf="isExpiredTrial()" svgIcon="streamline_outlined:trial_expiry"></mat-icon>
          <div class="tn-heading">
            <span *ngIf="!isExpiredTrial()">{{ trialHeadline }}</span><span
              *ngIf="isExpiredTrial()">{{ expiredHeadline }}</span><span
              *ngIf="showExpirationDate">{{ trialStatus.expiryDate | date : 'dd.MM.yyyy' }}</span>
          </div>
        </div>
        <p class="paragraph paragraph--s">
          <span *ngIf="!isExpiredTrial()">{{ trialText }}</span>
          <span *ngIf="isExpiredTrial()">{{ expiredText }}</span>
        </p>
      </div>
      <div [class]="showButtons ? 'col-md-5' : ''" *ngIf="showButtons && !environment.whitelabel.hide_klett_shop">
        <div class="trial_btn_group">
          <button mat-flat-button color="secondary" type="button" (click)="openLicensetrialExtension()"
            *ngIf="isLastTrialWeek() || isExpiredTrial()">
            {{ translatedText.license_trial_phase }}
          </button>

          <button mat-flat-button color="primary" type="button" *ngIf="!environment.whitelabel.hide_klett_shop"
            (click)="openUrl('https://www.klett.de/lehrwerk/lautlesetutor-ausgabe-ab-2023/produktuebersicht')">
            {{ translatedText.license_trial_shop }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
