import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BookComplexityRatingComponent } from 'src/app/books/book-complexity-rating/book-complexity-rating.component';
import { BookPreferencesComponent } from 'src/app/books/book-preferences/book-preferences.component';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { BookListComponent } from '../../books/new-book-list/book-list.component';
import { VideoOverlayComponent } from '../../helpers/video-overlay/video-overlay.component';
import { NotificationService } from '../../providers/notification.service';
import { TaskService } from '../../providers/task.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';

export interface Tasks {}
export interface Students {
  username: string;
  _id: string;
  role: string;
}

@Component({
  selector: 'app-dashboard-student',
  templateUrl: './dashboard-student.component.html',
  styleUrls: ['./dashboard-student.component.scss'],
})
export class DashboardStudentComponent implements OnInit {
  support_url = environment.whitelabel.support_url;
  externalAssetsUrl = environment.externalAssets;
  selfReadingActive: boolean = true;
  activeTab = 'isActive';
  studentId;
  tasks: Tasks[] = [];
  medals = {
    gold: 0,
    silver: 0,
    bronze: 0,
  };
  tasksPerformed = 0;
  wordsRead = 0;
  readingPoints = 0;
  awards;
  taskResults = [];
  activetasks = [];
  finishedtasks = [];
  expiredtasks = [];
  activeTabCount: number;
  finishedTabLabel: string = '';
  expiredTabLabel: string = '';
  notifications: Notification[] = [];
  diagnosticTasks = [];
  today = new Date();
  //multipleTasks;
  diagnosticPhases;
  translatedText: any;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private authService: AuthService,
    private taskService: TaskService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef,
    private studentService: StudentService,
    private capacitorService: CapacitorService,
    private translationService: TranslationService,
  ) {}

  ngOnInit() {
    this.studentId = this.authService.getStudentId();

    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.taskService.getPhaseDates();
    this.taskService
      .getPhaseDatesUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.diagnosticPhases = res;
        console.log(res);
      });

    this.taskService.getTasksToStudents(this.studentId);
    this.taskService
      .getTasksToStudentsUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((tasks: Tasks[]) => {
        //console.log(tasks)
        this.tasks = [...tasks];
        this.activetasks = this.tasks.filter((el) => {
          const excludedTypes = [
            'diagnostic-pre',
            'diagnostic-mid',
            'diagnostic-post',
          ];
          return (
            el['status'] === 'active' && !excludedTypes.includes(el['type'])
          );
        });

        this.finishedtasks = this.tasks.filter((el) => {
          const excludedTypes = [
            'diagnostic-pre',
            'diagnostic-mid',
            'diagnostic-post',
          ];
          return (
            el['status'] === 'finished' && !excludedTypes.includes(el['type'])
          );
        });

        this.expiredtasks = this.tasks.filter((el) => {
          const excludedTypes = [
            'diagnostic-pre',
            'diagnostic-mid',
            'diagnostic-post',
          ];
          return (
            el['status'] === 'expired' && !excludedTypes.includes(el['type'])
          );
        });

        this.diagnosticTasks = this.tasks.filter((el) => {
          const excludedTypes = [
            'diagnostic-pre',
            'diagnostic-mid',
            'diagnostic-post',
          ];
          const activeDiagnostics =
            el['status'] === 'active' && excludedTypes.includes(el['type']);
          return activeDiagnostics;
        });

        // diagnostic counter % diagnostic tasks array
        const diagnosticCountArray = [];
        this.diagnosticTasks.filter((item) => {
          let dignosticType;
          if (item.type === 'diagnostic-pre') {
            dignosticType = 0;
          } else if (item.type === 'diagnostic-mid') {
            dignosticType = 1;
          } else if (item.type === 'diagnostic-post') {
            dignosticType = 2;
          }
          if (item.deadline > this.today.toISOString() && !item.completed_at) {
            //if diagnostic is in date phases
            const deadlineDate = new Date(item.deadline);
            const today = new Date();
            const fourWeeksFromNow = new Date(today);
            fourWeeksFromNow.setDate(today.getDate() + 28);

            if (deadlineDate.getTime() <= fourWeeksFromNow.getTime()) {
              diagnosticCountArray.push(item);
              return item;
            }
          }
        });
        //this.multipleTasks = this.activetasks.filter(task => task.mode === "multiple" && task.status === "active")

        this.activetasks = this.activetasks.filter((task) => {
          if (task['mode'] === 'multiple') {
            return task;
          } else if (task['type'] === 'autonomous-placement') {
            return task;
          } else {
            const deadline = moment(task?.deadline);
            const now = moment(); // Aktuelles Datum und Uhrzeit
            const deadlineWithinFourWeeks = deadline.isBetween(
              now,
              now.clone().add(28, 'days'),
              null,
              '[]',
            );
            return deadlineWithinFourWeeks;
          }
        });
        this.diagnosticTasks = diagnosticCountArray;
        if (diagnosticCountArray.length > 0) {
          this.activeTabCount = this.activetasks.length + 1;
        } else {
          this.activeTabCount = this.activetasks.length;
        }
      });

    //this.notificationService.getNotificationList();
    this.notificationService.getUnreadNotificationList();
    this.notificationService
      .getNotificationUpdateListener()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: any) => {
        this.notifications = notifications;
        //console.log(this.notifications);
      });

    this.studentService.getStudentbyId(this.studentId).subscribe((res) => {
      setTimeout(() => {
        if (
          res['categoriesSeen'] === false ||
          res['categories'] === undefined
        ) {
          this.openBookPreferences();
          // update categoriesSeen to show preference only one time
          this.updateStudent();
        }
      }, 1000);
    });
  }

  updateStudent() {
    let formData = {
      _id: this.studentId,
      categoriesSeen: true,
    };
    console.log(formData);
    this.studentService
      .updateStudentPreferences(formData)
      .subscribe({ next: (res) => {}, error: (error: any) => {} });
  }

  openBookPreferences(): void {
    let dialogRef = this.dialog.open(BookPreferencesComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'books-preferences-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'books-preferences-overlay-panel_backdrop',
      data: {
        //step: "selection"
      },
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.notificationService.getUnreadNotificationList();

    //console.log(tabChangeEvent);
    if (tabChangeEvent.index === 0) {
      this.activeTab = 'isActive';
    } else if (tabChangeEvent.index === 1) {
      this.activeTab = 'isFinished';
    } else if (tabChangeEvent.index === 2) {
      this.activeTab = 'isExpired';
    } else if (tabChangeEvent.index === 3) {
      this.activeTab = 'isSelfReading';
    }
  }

  openUrl(url: string) {
    this.capacitorService.openUrl(url);
  }

  startSelfReading() {
    this.taskService
      .createTaskForSelfReading(this.studentId)
      .subscribe((task) => {
        console.log(task);
        let dialogRef = this.dialog.open(BookListComponent, {
          width: '100%',
          autoFocus: false,
          panelClass: 'books-overlay-panel',
          hasBackdrop: false,
          disableClose: true,
          data: {
            type: task.type,
            taskId: task._id,
            userTaskUuid: task.uuid,
          },
          backdropClass: 'books-overlay-panel-backdrop',
        });
        // dialogRef.disableClose = true;
        dialogRef.afterClosed().subscribe((res) => {});
      });
  }

  showVideo(filename, title) {
    let dialogRef = this.dialog.open(VideoOverlayComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'video-overlay-panel',
      // hasBackdrop: false,
      disableClose: false,
      backdropClass: 'video-overlay-panel-backdrop',
      data: {
        title: title,
        url: this.externalAssetsUrl + '/assets/videos/' + filename,
      },
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  // TODO: unused?
  openBookRating(): void {
    let dialogRef = this.dialog.open(BookComplexityRatingComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'book-complexity-rating-overlay-panel',
      // hasBackdrop: false,
      disableClose: true,
      backdropClass: 'book-complexity-rating-overlay-panel-backdrop',
    });
    // dialogRef.disableClose = true;
    dialogRef.afterClosed().subscribe((res) => {});
  }

  showAlert() {
    alert('Under Construction');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
