import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  LOCALE_ID,
  APP_INITIALIZER,
  ErrorHandler,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatePipe, DecimalPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './user/login/login.module';
import { QrLoginModule } from './user/qr-login/qr-login.module';
import { DashboardStudentModule } from './dashboard/dashboard-student/dashboard-student.module';
import { DashboardTeacherModule } from './dashboard/dashboard-teacher/dashboard-teacher.module';
import { DashboardTasksModule } from './dashboard/dashboard-tasks/dashboard-tasks.module';
import { BookListModule } from './books/new-book-list/book-list.module';
import { BookListStudentModule } from './books/book-list-student/book-list-student.module';
import { BookPreviewModule } from './books/book-preview/book-preview.module';
import { AuthGuard } from './auth/auth.guard';
import { AuthInterceptor } from './auth/auth-interceptor';
import { ErrorInterceptor } from './error/error-interceptor';
import { BookReaderModule } from './book-reader/book-reader.module';
import { RecordRTCModule } from './record-rtc/record-rtc.module';
import { HomeComponent } from './static/home/home.component';
import { HomeLoginComponent } from './static/home-login/home-login.component';
import { MaterialModule } from './material.module';
import { ChangePasswordModule } from './user/change-password/change-password.module';
import { CreatePasswordModule } from './user/create-password/create-password.module';
import { ForgotPasswordModule } from './user/forgot-password/forgot-password.module';

import { ClassDetailModule } from './class/class-detail/class-detail.module';
import { ClassDeleteModule } from './class/class-delete/class-delete.module';
import { ClassPdfPrintModule } from './class/class-pdf-print/class-pdf-print.module';
import { DiagnosticCreateModule } from './diagnostic/new-diagnostic-create/diagnostic-create.module';
import { StudentOverviewModule } from './student/student-overview/student-overview.module';
import { StudentEditModule } from './student/student-edit/student-edit.module';
import { StudentDeleteModule } from './student/student-delete/student-delete.module';
import { StudentResultsModule } from './student/student-results/student-results.module';
import { TeacherDeleteModule } from './teacher/teacher-delete/teacher-delete.module';
import { TaskRepeatModule } from './task/task-repeat/task-repeat.module';
import { TaskListModule } from './task/task-list/task-list.module';
import { TaskDeleteModule } from './task/task-delete/task-delete.module';
import { TaskDetailModule } from './task/task-detail/task-detail.module';
import { SnackbarModule } from './helpers/snackbar/snackbar.module';
import { IconsModule } from './helpers/icons/icons.module';
import { VerificationModule } from './helpers/verification/verification.module';
import { PdfOverlayModule } from './helpers/pdf-overlay/pdf-overlay.module';
import { StudentReportModule } from './student/student-report/student-report.module';
import { StudentTaskListModule } from './student/student-task-list/student-task-list.module';
import { ForgotPasswordOverlayModule } from './helpers/forgot-password-overlay/forgot-password-overlay.module';
import { ReportErrorModule } from './report-error/report-error.module';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { registerLocaleData } from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';
import { TeacherSidebarModule } from './teacher/teacher-sidebar/teacher-sidebar.module';
import { TeacherMobileMenuModule } from './teacher/teacher-mobile-menu/teacher-mobile-menu.module';
import { TeacherTaskListModule } from './teacher/teacher-task-list/teacher-task-list.module';
import { TrainingTaskCreateModule } from './training-task/new-training-task-create/training-task-create.module';
import { NotificationOverlayModule } from './notification/notification-overlay/notification-overlay.module';
import { NotificationListModule } from './notification/notification-list/notification-list.module';
import { AwardOverlayModule } from './helpers/award-overlay/award-overlay.module';
import { TaskStartOverlayModule } from './helpers/task-start-overlay/task-start-overlay.module';
import { TaskPreviewModule } from './task/task-preview/task-preview.module';
import { BookPreviewStudentModule } from './books/book-preview-student/book-preview-student.module';
import { BookViewStudentModule } from './books/book-view-student/book-view-student.module';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import { AdminDashboardModule } from './admin/new-admin-dashboard/admin-dashboard.module';
import { AdminGroupListModule } from './admin/admin-group/admin-group-list/admin-group-list.module';
import { AdminManagementModule } from './admin/admin-management/admin-management.module';
import { AdminTeacherListModule } from './admin/admin-teacher/admin-teacher-list/admin-teacher-list.module';
import { AdminTeacherCreateModule } from './admin/admin-teacher/new-admin-teacher-create/admin-teacher-create.module';
import { PasswordChangedOverlayModule } from './helpers/password-changed-overlay/password-changed-overlay.module';
import { PinCodeModule } from './helpers/pin-code/pin-code.module';
import { AdminGroupCreateModule } from './admin/admin-group/admin-group-create/admin-group-create.module';
import { LicenseAddModule } from './license/license-add/license-add.module';
import { SchoolAdminCreateModule } from './admin/school-admin/school-admin-create/school-admin-create.module';
import { SchoolAdminCreateTrialModule } from './admin/school-admin/school-admin-create-trial/school-admin-create-trial.module';
import { SchoolAdminCreateNoSchoolModule } from './admin/school-admin/school-admin-create-no-school/school-admin-create-no-school.module';
import { ChangeStudentPasswordModule } from './helpers/change-student-password/change-student-password.module';
import { AdminTeacherManagingModule } from './admin/admin-teacher/admin-teacher-managing/admin-teacher-managing.module';
import { TeacherEditModule } from './teacher/teacher-edit/teacher-edit.module';
import { DashboardLicenseModule } from './dashboard/dashboard-license/dashboard-license.module';
import { TeacherSelectionModule } from './teacher/teacher-selection/teacher-selection.module';
import { TrainingChartModule } from './widget/training-chart/training-chart.module';
import { DiagnosticChartModule } from './widget/diagnostic-chart/diagnostic-chart.module';
import { StudentsEditModule } from './student/students-edit/students-edit.module';
import { StudentsAddModule } from './student/students-add/students-add.module';
import { BookPreferencesModule } from './books/book-preferences/book-preferences.module';
import { AdminTeacherEditModule } from './admin/admin-teacher/admin-teacher-edit/admin-teacher-edit.module';
import { TaskErrorOverlayModule } from './helpers/task-error-overlay/task-error-overlay.module';
import { TaskCompletedOverlayModule } from './helpers/task-completed-overlay/task-completed-overlay.module';
import { BookComplexityRatingModule } from './books/book-complexity-rating/book-complexity-rating.module';
import { LicenseDeclineModule } from './license/license-decline/license-decline.module';
import { AdminAddExistingStudentsModule } from './admin/admin-student/admin-add-existing-students/admin-add-existing-students.module';
import { DashboardDiagnosticsModule } from './dashboard/dashboard-diagnostics/dashboard-diagnostics.module';
import { ArchivmentsOverlayModule } from './helpers/archivments-overlay/archivments-overlay.module';
import { AdminGroupManagingModule } from './admin/admin-group/admin-group-managing/admin-group-managing.module';
import { PasswordsWarnModule } from './helpers/passwords-warn/passwords-warn.module';
import { DiagnosticRepeatModule } from './diagnostic/diagnostic-repeat/diagnostic-repeat.module';
import { TaskEditTrainingModule } from './task/task-edit-training/task-edit-training.module';
import { TaskEditDiagnosticModule } from './task/task-edit-diagnostic/task-edit-diagnostic.module';
import { HelpdeskOverlayModule } from './helpers/helpdesk-overlay/helpdesk-overlay.module';
import { AutomodeOverlayModule } from './helpers/automode-overlay/automode-overlay.module';
import { TimemodeOverlayModule } from './helpers/timemode-overlay/timemode-overlay.module';
import { StudentPdfOverlayInfoModule } from './helpers/student-pdf-overlay-info/student-pdf-overlay-info.module';
import { AdminListModule } from './admin/admin-list/admin-list.module';
import { AdminEditModule } from './admin/admin-edit/admin-edit.module';
import { AdminManagingOverlayModule } from './helpers/admin-managing-overlay/admin-managing-overlay.module';
import { ReadingLevelOverlayModule } from './helpers/reading-level-overlay/reading-level-overlay.module';
import { CsvImportTeacherModule } from './csv-import/csv-import-teacher/csv-import-teacher.module';
import { CsvImportStudentModule } from './csv-import/csv-import-students/csv-import-student.module';
import { BetaInfoModule } from './helpers/beta-info/beta-info.module';
import { HintBoxModule } from './helpers/hint-box/hint-box.module';
import { LoginDialogModule } from './static/home/login-dialog/login-dialog.module';
import { SubscribeDialogModule } from './static/home/subscribe-dialog/subscribe-dialog.module';
import { ParentsLandingPageModule } from './parents/parents-landing-page/parents-landing-page.module';
import { ContactModule } from './static/contact/contact.module';
import { ConfirmDialogModule } from './helpers/confirm-dialog/confirm-dialog.module';
import { MicrophoneCheckModule } from './microphone-check/microphone-check.module';
import { DashboardHelpDeskModule } from './dashboard/dashboard-help-desk/dashboard-help-desk.module';
import { SchoolDetailModule } from './school/school-detail/school-detail.module';
import { LicenseCheckModule } from './license/license-check/license-check.module';
import { SchoolLicenseDetailModule } from './school/school-license-detail/school-license-detail.module';
import { SchoolCreateModule } from './school/school-create/school-create.module';
import { SchoolEditModule } from './school/school-edit/school-edit.module';
import { SchoolDeleteModule } from './school/school-delete/school-delete.module';
import { ReleaseNotesModule } from './static/release-notes/release-notes.module';
import { NonSchoolregisterModule } from './school/non-school-register/non-school-register.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TranslationService } from './providers/translation.service';
import { ExpertGroupModule } from './helpers/expert-group/expert-group.module';
import { SchoolsOnlyDialogModule } from './helpers/schools-only/schools-only.module';
import { Observable } from 'rxjs';
import { SessionUploadOverlayModule } from './helpers/session-upload-overlay/session-upload-overlay.module';
import { LanguageChangeAlertModule } from './helpers/language-change-alert/language-change-alert.module';
import { AutenticatorSetupModule } from './user/authenticator-setup/authenticator-setup.module';
import { LicenseDistributionModule } from './license/license-distribution/license-distribution.module';
import { LicenseTrialExtensionModule } from './license/license-trial-extension/license-trial-extension.module';
import { GenerateClassPDFModule } from './helpers/generate-class-pdf/generate-class-pdf.module';
import { CancelAddStudentModule } from './helpers/cancel-add-student/cancel-add-student.module';
import { CreateLearnersModule } from './helpers/create-learners/create-learners.module';
import { PipeModule } from './pipes/pipe.module';
import { AdminProfileManagementModule } from './admin/admin-profile-management/admin-profile-management.module';
import { ActivateLicenseModule } from './helpers/activate-license/activete-license.module';
import { UnassignedStudentToClassModule } from './helpers/unassigned-student-to-class/unassigned-student-to-class.module';
import { AdminNewSchoolYearModule } from './admin/admin-new-school-year/admin-new-school-year.module';
import { ClassesYearUpdateModule } from './helpers/classes-year-update/classes-year-update.module';
import { ClassesUpdateBannerModule } from './helpers/classes-year-update/classes-update-banner/classes-update-banner-module';
import { AccountDeleteModule } from './admin/admin-profile-management/account-delete/account-delete.module';
import { IndividualLearnersSelectionModule } from './helpers/individual-learners-selection/individual-learners-selection.module';
import { BooksFilterModule } from './books/books-filter/books-filter.module';
import { AutomodeTaskFrequencyModule } from './task/automode-task-frequency/automode-task-frequency.module';
import { TaskCreationBookViewModule } from './books/task-creation-book-view/task-creation-book-view.module';
import { BookViewModule } from './books/book-view/book-view.module';
//old task creation import temporarily
import { OldTrainingTaskCreateModule } from './training-task/training-task-create/training-task-create.module';
import { OldDiagnosticCreateModule } from './diagnostic/diagnostic-create/diagnostic-create.module';
import { OldBookListModule } from './books/book-list/book-list.module';

import { NotificationMenuModule } from './notification/notification-menu/notification-menu.module';
import { ZoomWarningModule } from './helpers/zoom-warning/zoom-warning.module';
import { TermsOfUseModule } from './static/terms-of-use/terms-of-use.module';
import { TermsAndConditionsModule } from './static/terms-and-conditions/terms-of-use.module';
import { OrderProcessingAgreementModule } from './static/order-processing-agreement/order-processing-agreement.module';
import { StudentLimitReachedModule } from './helpers/student-limit-reached/student-limit-reached.module';
import { TeacherTransferModule } from './teacher/teacher-transfer/teacher-transfer.module';
import { InvitationDeleteModule } from './helpers/invitation-delete/invitation-delete.module';
import { InvitationResendModule } from './helpers/invitation-resend/invitation-resend.module';
import { DueDateSelectorModule } from './training-task/due-date-selector/due-date-selector.module';
import { EditTaskDeadlineModule } from './teacher/teacher-task-list/edit-task-deadline/edit-task-deadline.module';
import { CsvImportModule } from './csv-import/new-csv-import/csv-import.module';
import { LanguagesCSVModule } from './helpers/languages-csv/languages-csv.module';
// register Swiper custom elements
register();
registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [AppComponent, HomeComponent, HomeLoginComponent],
  imports: [
    AccountDeleteModule,
    //remove these 3 below after updating task creation flow
    OldBookListModule,
    OldDiagnosticCreateModule,
    OldTrainingTaskCreateModule,
    NotificationMenuModule,
    UnassignedStudentToClassModule,
    CreateLearnersModule,
    CancelAddStudentModule,
    GenerateClassPDFModule,
    LanguageChangeAlertModule,
    ParentsLandingPageModule,
    ContactModule,
    SubscribeDialogModule,
    LoginDialogModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    QrLoginModule,
    DashboardStudentModule,
    DashboardTeacherModule,
    DashboardTasksModule,
    BookListModule,
    BookListStudentModule,
    BookPreviewModule,
    BookViewModule,
    BookReaderModule,
    RecordRTCModule,
    MaterialModule,
    CreatePasswordModule,
    EditTaskDeadlineModule,
    ClassDeleteModule,
    ClassPdfPrintModule,
    ClassDetailModule,
    DiagnosticCreateModule,
    StudentOverviewModule,
    StudentEditModule,
    StudentDeleteModule,
    StudentResultsModule,
    TeacherDeleteModule,
    TaskEditTrainingModule,
    TaskEditDiagnosticModule,
    TaskListModule,
    TaskDetailModule,
    TaskDeleteModule,
    SnackbarModule,
    IconsModule,
    ForgotPasswordModule,
    VerificationModule,
    StudentReportModule,
    PdfOverlayModule,
    StudentTaskListModule,
    ForgotPasswordOverlayModule,
    TaskRepeatModule,
    ReportErrorModule,
    TeacherSidebarModule,
    TeacherMobileMenuModule,
    TeacherTaskListModule,
    TrainingTaskCreateModule,
    NotificationOverlayModule,
    NotificationListModule,
    AwardOverlayModule,
    TaskStartOverlayModule,
    TaskPreviewModule,
    BookPreviewStudentModule,
    BookViewStudentModule,
    AdminDashboardModule,
    AdminGroupListModule,
    AdminManagementModule,
    AdminTeacherListModule,
    AdminTeacherCreateModule,
    ChangePasswordModule,
    PasswordChangedOverlayModule,
    PinCodeModule,
    AdminGroupCreateModule,
    LicenseAddModule,
    SchoolAdminCreateModule,
    SchoolAdminCreateTrialModule,
    SchoolAdminCreateNoSchoolModule,
    ChangeStudentPasswordModule,
    AdminTeacherManagingModule,
    TeacherEditModule,
    DashboardLicenseModule,
    TeacherSelectionModule,
    TrainingChartModule,
    DiagnosticChartModule,
    StudentsEditModule,
    StudentsAddModule,
    BookPreferencesModule,
    AdminTeacherEditModule,
    TaskErrorOverlayModule,
    TaskCompletedOverlayModule,
    BookComplexityRatingModule,
    LicenseDeclineModule,
    AdminAddExistingStudentsModule,
    DashboardDiagnosticsModule,
    ArchivmentsOverlayModule,
    AdminGroupManagingModule,
    PasswordsWarnModule,
    DiagnosticRepeatModule,
    HelpdeskOverlayModule,
    AutomodeOverlayModule,
    TimemodeOverlayModule,
    StudentPdfOverlayInfoModule,
    AdminListModule,
    AdminEditModule,
    AdminManagingOverlayModule,
    ReadingLevelOverlayModule,
    CsvImportTeacherModule,
    CsvImportStudentModule,
    BetaInfoModule,
    HintBoxModule,
    ConfirmDialogModule,
    MicrophoneCheckModule,
    DashboardHelpDeskModule,
    SchoolDetailModule,
    LicenseCheckModule,
    SchoolLicenseDetailModule,
    SchoolCreateModule,
    SchoolEditModule,
    SchoolDeleteModule,
    ReleaseNotesModule,
    NonSchoolregisterModule,
    ExpertGroupModule,
    SchoolsOnlyDialogModule,
    SessionUploadOverlayModule,
    AutenticatorSetupModule,
    LicenseDistributionModule,
    LicenseTrialExtensionModule,
    PipeModule,
    AdminProfileManagementModule,
    ActivateLicenseModule,
    AdminNewSchoolYearModule,
    ClassesYearUpdateModule,
    ClassesUpdateBannerModule,
    IndividualLearnersSelectionModule,
    BooksFilterModule,
    AutomodeTaskFrequencyModule,
    TaskCreationBookViewModule,
    ZoomWarningModule,
    TermsOfUseModule,
    TermsAndConditionsModule,
    OrderProcessingAgreementModule,
    StudentLimitReachedModule,
    TeacherTransferModule,
    InvitationDeleteModule,
    InvitationResendModule,
    DueDateSelectorModule,
    CsvImportModule,
    LanguagesCSVModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    /* {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}}, */
    TranslationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: (translationService: TranslationService) => {
        return () =>
          new Observable((subscriber) => {
            translationService.getTranslations().subscribe({
              next: () => subscriber.complete(),
              error: () => subscriber.error(),
            });
          });
      },
      deps: [TranslationService],
      multi: true,
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: (translationService: TranslationService) =>
        translationService.getCurrentLanguage() === 'de_de' ? 'de-DE' : 'en-US',
      deps: [TranslationService],
    },
    {
      provide: LOCALE_ID,
      useFactory: (translationService: TranslationService) =>
        translationService.getCurrentLanguage() === 'de_de' ? 'de-DE' : 'en-US',
      deps: [TranslationService],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    AuthGuard,
    DatePipe,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
