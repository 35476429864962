import { ContentObserver } from '@angular/cdk/observers';
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Console } from 'console';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

export interface DialogData {
  source: string;
}

export interface DialogData {}
@Component({
  selector: 'app-teacher-transfer',
  templateUrl: './teacher-transfer.component.html',
  styleUrls: ['./teacher-transfer.component.scss'],
})
export class TeacherTransferComponent implements OnInit, OnDestroy {
  environment = environment;
  translatedText: any;
  targetSchool: string = '';
  isDeclined: boolean = false;
  isAccepted: boolean = false;
  selectedOption: string = '';
  isSubmited: boolean = false;
  isLoading: boolean = false;
  schoolName: string = '';
  isError: boolean = false;
  invitationId: string = '';
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  constructor(
    private translationService: TranslationService,
    private dialogRef: MatDialogRef<TeacherTransferComponent>,
    private authService: AuthService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
  ) {
    if (injectedData) {
      if (typeof injectedData['targetSchoolName'] !== 'undefined') {
        this.schoolName = this.injectedData['targetSchoolName'];
      }
      if (typeof injectedData['targetSchool'] !== 'undefined') {
        this.targetSchool = this.injectedData['targetSchool'];
      }
      if (typeof injectedData['invitationId'] !== 'undefined') {
        this.invitationId = this.injectedData['invitationId'];
      }
    }
  }

  ngOnInit(): void {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
  }

  showStep(value: string) {
    switch (value) {
      case 'close':
        this.dialogRef.close('close');
        break;
      case 'decline':
        this.isDeclined = true;
        break;
      case 'accept':
        this.isAccepted = true;
        break;
      case 'success':
        this.dialogRef.close('close');
        this.router.navigate(['/admin-profile-management']);
        this.authService.setAuthStatus();
        break;
      default:
        break;
    }
  }

  submitTransfer() {
    this.isLoading = true;

    let data = {
      targetSchool: this.targetSchool,
      mergeGroups: false,
      mergeTasks: false,
      translatedText: this.translatedText,
    };

    if (this.selectedOption == '1') {
      data.mergeGroups = true;
      data.mergeTasks = true;
    } else if (this.selectedOption == '2') {
      data.mergeGroups = true;
      data.mergeTasks = false;
    } else if (this.selectedOption == '3') {
      data.mergeGroups = false;
      data.mergeTasks = false;
    }
    console.log(data);

    this.authService.transferTeacher(data).subscribe({
      next: (res: any) => {
        this.updateUserInStorage(res.school);
      },
      error: (error: any) => {
        this.isError = true;
      },
    });
  }

  updateUserInStorage(schoolData: any) {
    const storage = localStorage.getItem('user')
      ? localStorage
      : sessionStorage;
    let user = JSON.parse(storage.getItem('user')!);

    if (user) {
      user = {
        ...user,
        role: 'teacher',
        schoolId: schoolData._id,
        schoolUuid: schoolData.uuid,
        schoolState: schoolData.state,
        trial: { isActive: false },
      };

      storage.setItem('user', JSON.stringify(user));
      const token = storage.getItem('token');
      this.authService.setUser(user, token, storage === localStorage);
      this.isAccepted = false;
      this.isSubmited = true;
    }
  }

  declineInvitation() {
    this.isLoading = true;
    const invintationData = {
      status: 'declined',
      invitationId: this.invitationId,
      type: 'update',
    };
    this.authService
      .updateInvitation(invintationData, this.translatedText)
      .subscribe({
        next: (res) => {
          this.isDeclined = true;
          this.isLoading = false;
        },
        error: (error: any) => {
          this.isError = true;
          this.isLoading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
