<div class="csv-import-teacher-container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="true" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <ng-container *ngIf="!fileSended && !isLoading && !isWrongFormat">
    <div class="csv-info">
      <div *ngIf="!isError">
        <mat-icon svgIcon="streamline_outlined:add-page-alt"></mat-icon>
        <h3 class="h3 h3--bold">
          {{ translatedText.csv_import_teacher_title }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.csv_import_teacher_info_text }}
        </p>
      </div>
      <div *ngIf="isError">
        <mat-icon class="error-icon" svgIcon="streamline_outlined:warning-circle"></mat-icon>
        <h3 class="h3 h3--bold">
          {{ translatedText.csv_import_teacher_error }}
        </h3>
        <p class="paragraph paragraph--xl">
          {{ translatedText.csv_import_teacher_error_text }}
        </p>
      </div>
    </div>

    <table>
      <tr>
        <th>{{ translatedText.csv_import_teacher_csv_column }}</th>
        <th>{{ translatedText.csv_import_teacher_attribute }}</th>
        <th></th>
      </tr>
      <tr>
        <td>{{ translatedText.csv_import_teacher_firstname }}</td>
        <td>{{ translatedText.csv_import_teacher_firstname }}</td>
        <td><mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon></td>
      </tr>
      <div class="csv-border"></div>
      <tr>
        <td>{{ translatedText.csv_import_teacher_lastname }}</td>
        <td>{{ translatedText.csv_import_teacher_lastname }}</td>
        <td><mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon></td>
      </tr>
      <div class="csv-border"></div>
      <tr>
        <td>{{ translatedText.csv_import_teacher_salutation }}</td>
        <td>{{ translatedText.csv_import_teacher_salutation }}</td>
        <td><mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon></td>
      </tr>
      <div class="csv-border"></div>
      <tr>
        <td>{{ translatedText.csv_import_teacher_email }}</td>
        <td>{{ translatedText.csv_import_teacher_email }}</td>
        <td><mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon></td>
      </tr>
      <!-- <tr>
                <td>Rolle</td>
                <td>Rollen</td>
                <td><mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon></td>
            </tr> -->
      <!-- <div class="csv-border"></div>
            <tr>
                <td>Klasse(n)</td>
                <td>Klassen</td>
                <td><mat-icon svgIcon="streamline_outlined:help-circle"></mat-icon></td>
            </tr>      -->
    </table>

    <div class="view_button">
      <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="true">
        {{ translatedText.csv_import_teacher_cancel }}
      </button>
      <button mat-flat-button color="primary" type="button" (click)="selectFile()">
        {{ !isError ? translatedText.csv_import_teacher_select_file : translatedText.csv_import_teacher_retry }}
      </button>
      <input type="file" (change)="onFileSelected($event)" #fileInput style="display: none" />
    </div>
  </ng-container>

  <ng-container *ngIf="isLoading && !isWrongFormat">
    <div class="csv-loading-wrap">
      <app-loading-spinner></app-loading-spinner>
      <h3 class="h3 h3--bold">
        {{ translatedText.csv_import_teacher_import_text }}
      </h3>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && fileSended && !isWrongFormat">
    <div class="csv-sended-wrap">
      <mat-icon svgIcon="streamline_outlined:check_mark"></mat-icon>
      <h3 class="h3 h3--bold">
        {{ translatedText.csv_import_teacher_import_success }}
      </h3>
      <p class="paragraph paragraph--xl">
        <strong
          >{{ teachers }} {{ translatedText.csv_import_teacher_from }} {{ teachers }}
          {{ translatedText.csv_import_teacher_rows }}</strong
        >
        {{ translatedText.csv_import_teacher_import_success_text }}
      </p>
      <div class="view_button">
        <button mat-flat-button color="primary" type="button" [mat-dialog-close]="true">
          {{ translatedText.csv_import_teacher_to_list }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isWrongFormat">
    <div class="csv-sended-wrap">
      <mat-icon class="error-icon" svgIcon="streamline_outlined:warning-circle"></mat-icon>
      <h3 class="h3 h3--bold">{{ translatedText.csv_import_teacher_error }}</h3>
      <p class="paragraph paragraph--xl">
        {{ translatedText.csv_import_teacher_wrong_format }}
      </p>
      <div class="view_button">
        <button mat-flat-button color="secondary" type="button" [mat-dialog-close]="true">
          {{ translatedText.csv_import_teacher_cancel }}
        </button>
        <button mat-flat-button color="primary" type="button" (click)="selectNewFile()">
          {{ translatedText.csv_import_teacher_retry }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
