<div class="task-delete_container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <div class="task-delete_wrap">
    <mat-icon svgIcon="streamline_outlined:warning-circle-2" class="large-icon"></mat-icon>
    <h2 class="h2 h2--bold">{{ translatedText.task_delete_apply }}</h2>
    <p class="paragraph paragraph--xl">
      {{ translatedText.task_delete_title }}
    </p>
    <div class="buttons-wrapper">
      <button mat-flat-button color="secondary" type="button" [mat-dialog-close]>
        {{ translatedText.training_task_create_cancel }}
      </button>
      <button
        mat-flat-button
        color="warning"
        type="button"
        (click)="source === 'detail' ? deleteTask() : deleteSingleTask()"
      >
        {{ translatedText.task_delete_apply }}
      </button>
    </div>
    <!--  <img
      class="footer_task-delete_img"
      [src]="'/assets/' + environment.whitelabel.logo"
      alt=""
    /> -->
  </div>
</div>
