<div class="invitation-resend-wrap" *ngIf="!isSubmited">
  <mat-icon svgIcon="streamline_outlined:warning-circle-2" class="warn-icon"> </mat-icon>
  <h2 class="h2"> {{ translatedText.invitation_resend_headline }}</h2>
  <p class="paragraph paragraph--xl">{{
      translatedText.t('invitation_resend_text', {
          firstname:firstname,
          lastname: lastname
      })
    }}</p>
  <div class="button_wrap">
    <button mat-flat-button color="secondary" [mat-dialog-close]="'close'" [disabled]="isLoading" type="button"
      class="mat-flat-button--full" aria-label="cancel">
      {{ translatedText.invitation_resend_cancel }}
    </button>

    <button mat-flat-button color="primary" [disabled]="isLoading" (click)="resendInvatation()" type="button"
      class="mat-flat-button--full" aria-label="Connect">
      {{ translatedText.invitation_resend_button }}
    </button>
  </div>
</div>

<div class="invitation-resend-wrap" *ngIf="isSubmited">
  <mat-icon svgIcon="streamline_outlined:check_circle"></mat-icon>
  <h2 class="h2"> {{ translatedText.invitation_resend_success_headline }}</h2>
  <p class="paragraph paragraph--xl">{{
      translatedText.t('invitation_resend_success_text', {
          firstname:firstname,
          lastname: lastname
      })
    }}</p>
  <div class="button_wrap">
    <button mat-flat-button color="primary" [mat-dialog-close]="'close'" [disabled]="isLoading" type="button"
      class="mat-flat-button--full" aria-label="cancel">
      {{ translatedText.invitation_resend_success_close }}
    </button>
  </div>
</div>
