import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
/* import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component'; */
import { TranslationService } from 'src/app/providers/translation.service';
import { TeacherService } from 'src/app/providers/teacher.service';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../../../helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from '../../../helpers/snackbar/success-snackbar/success-snackbar.component';

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss'],
})
export class AccountDeleteComponent implements OnInit, OnDestroy {
  translatedText: any;
  teacherId: any;
  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private teacherService: TeacherService,
    private authService: AuthService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.teacherId = this.authService.getTeacherId();
  }

  deleteTeacher() {
    this.teacherService.selfDeleteTeacher(this.teacherId).subscribe({
      next: (res) => {
        this.dialog.closeAll();
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.admin_managing_delete_teacher,
          duration: 3000,
          horizontalPosition: 'left',
        });
        // Logout afterwards
        this.authService.logout();
      },
      error: (error) => {
        let message = this.translatedText?.admin_managing_error;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
