import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation.service';
import { StudentService } from 'src/app/providers/student.service';

@Component({
  selector: 'app-languages-csv',
  templateUrl: './languages-csv.component.html',
  styleUrls: ['./languages-csv.component.scss'],
})
export class LanguagesCSVComponent implements OnInit, OnDestroy {
  translatedText: any;
  languages: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();
  isLoading: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private studentService: StudentService,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });

    this.getLanguagesList();
  }

  getLanguagesList() {
    this.studentService.getLanguages().subscribe({
      next: (res) => {
        this.languages = res;
        this.isLoading = false;
      },
      error: (err) => {
        console.error('Error:', err);
        this.isLoading = false;
      },
    });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
