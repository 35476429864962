import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import { CapacitorService } from 'src/app/providers/capacitor.service';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt, 'it');

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  environment = environment;
  externalAssetsUrl = environment.externalAssets;
  showSponsorLogos: boolean = true;
  priceSelection: string = 'training-single';
  translatedText: any;
  trainingSinglePlans = [
    {
      amount: 1,
      package_a: {
        price: 25,
        url: 'https://www.klett.de/produkt/isbn/ECN20168STA12',
      },
      package_b: {
        price: 40,
        url: 'https://www.klett.de/produkt/isbn/ECN20182STA12',
      },
    },
    {
      amount: 5,
      package_a: {
        price: 113,
        url: 'https://www.klett.de/produkt/isbn/ECN20169STA12',
      },
      package_b: {
        price: 180,
        url: 'https://www.klett.de/produkt/isbn/ECN20183STA12',
      },
    },
    {
      amount: 10,
      package_a: {
        price: 213,
        url: 'https://www.klett.de/produkt/isbn/ECN20170STA12',
      },
      package_b: {
        price: 340,
        url: 'https://www.klett.de/produkt/isbn/ECN20184STA12',
      },
    },
    {
      amount: 20,
      package_a: {
        price: 400,
        url: 'https://www.klett.de/produkt/isbn/ECN20171STA12',
      },
      package_b: {
        price: 640,
        url: 'https://www.klett.de/produkt/isbn/ECN20185STA12',
      },
    },
    {
      amount: 30,
      package_a: {
        price: 563,
        url: 'https://www.klett.de/produkt/isbn/ECN20172STA12',
      },
      package_b: {
        price: 900,
        url: 'https://www.klett.de/produkt/isbn/ECN20186STA12',
      },
    },
    // {
    //   amount: 100,
    //   package_a: {
    //     price: 1000,
    //     url: 'https://www.klett.de/produkt/isbn/ECN20172STA12',
    //   },
    //   package_b: {
    //     price: 1900,
    //     url: 'https://www.klett.de/produkt/isbn/ECN20186STA12',
    //   },
    // },
    // {
    //   amount: 150,
    //   package_a: {
    //     price: 1425,
    //     url: 'https://www.klett.de/produkt/isbn/ECN20172STA12',
    //   },
    //   package_b: {
    //     price: 2700,
    //     url: 'https://www.klett.de/produkt/isbn/ECN20186STA12',
    //   },
    // },
  ];
  trainingSchoolPlans = [
    {
      amount: 100,
      package_a: {
        price: 999,
        url: 'https://www.klett.de/produkt/isbn/ECN20173STA12',
      },
      package_b: {
        price: 1900,
        url: 'https://www.klett.de/produkt/isbn/ECN20187STA12',
      },
    },
    {
      amount: 150,
      package_a: {
        price: 1299,
        url: 'https://www.klett.de/produkt/isbn/ECN20174STA12',
      },
      package_b: {
        price: 2574,
        url: 'https://www.klett.de/produkt/isbn/ECN20188STA12',
      },
    },
    {
      amount: 200,
      package_a: {
        price: 1740,
        url: 'https://www.klett.de/produkt/isbn/ECN20175STA12',
      },
      package_b: {
        price: 3340,
        url: 'https://www.klett.de/produkt/isbn/ECN20189STA12',
      },
    },
    {
      amount: 250,
      package_a: {
        price: 2100,
        url: 'https://www.klett.de/produkt/isbn/ECN20176STA12',
      },
      package_b: {
        price: 4050,
        url: 'https://www.klett.de/produkt/isbn/ECN20190STA12',
      },
    },
    {
      amount: 300,
      package_a: {
        price: 2340,
        url: 'https://www.klett.de/produkt/isbn/ECN20177STA12',
      },
      package_b: {
        price: 4620,
        url: 'https://www.klett.de/produkt/isbn/ECN20191STA12',
      },
    },
    {
      amount: 350,
      package_a: {
        price: 2730,
        url: 'https://www.klett.de/produkt/isbn/ECN20178STA12',
      },
      package_b: {
        price: 5390,
        url: 'https://www.klett.de/produkt/isbn/ECN20192STA12',
      },
    },
    {
      amount: 400,
      package_a: {
        price: 3040,
        url: 'https://www.klett.de/produkt/isbn/ECN20179STA12',
      },
      package_b: {
        price: 6000,
        url: 'https://www.klett.de/produkt/isbn/ECN20193STA12',
      },
    },
    {
      amount: 450,
      package_a: {
        price: 3420,
        url: 'https://www.klett.de/produkt/isbn/ECN20180STA12',
      },
      package_b: {
        price: 6750,
        url: 'https://www.klett.de/produkt/isbn/ECN20194STA12',
      },
    },
    {
      amount: 500,
      package_a: {
        price: 3700,
        url: 'https://www.klett.de/produkt/isbn/ECN20181STA12',
      },
      package_b: {
        price: 7300,
        url: 'https://www.klett.de/produkt/isbn/ECN20195STA12',
      },
    },
  ];
  trainingSingleSliderIndexValue = 0;
  trainingSchoolSliderIndexValue = 0;
  trainingSingleSliderValues = [1, 5, 10, 20, 30];
  trainingSchoolSliderValues = [100, 150, 200, 250, 300, 350, 400, 450, 500];
  stepSliderValue: number = 1;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private capacitorService: CapacitorService,
    public translationService: TranslationService,
  ) {}

  ngOnInit(): void {
    this.translatedText = this.route.snapshot.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: (translation) => (this.translatedText = translation),
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }

  openUrl(url: string) {
    this.capacitorService.openUrl(url);
  }

  onSelectionChange(value) {
    console.log(value);
    this.priceSelection = value;
  }

  getVideoUrl(filename) {
    return this.externalAssetsUrl + '/assets/videos/' + filename;
  }

  numberTostring(number) {
    if (number > 999) {
      let str = number.toString();
      if (str.length > 3) {
        let first = str.substring(0, 1);
        str = str.substring(1);
        if (this.translationService.getCurrentLanguage() === 'en') {
          return first + ',' + str;
        } else {
          return first + '.' + str + ',00';
        }
      }
    } else if (this.translationService.getCurrentLanguage() === 'de') {
      return number + ',00';
    } else {
      return number;
    }
  }

  navigateToRegsiter() {
    this.router.navigate(['/registrierung']);
  }

  navigateToLogin(userRole) {
    this.router.navigate(['/login', { userRole: userRole }]);
  }

  replaceDomainName(name) {
    return name.replace(/@.*/, '@' + environment.whitelabel.domain_name);
  }

  sliderValueChanged(index: number, slider: string) {
    if (slider == 'training-single') {
      this.trainingSingleSliderIndexValue = index;
    }
    if (slider == 'training-school') {
      this.trainingSchoolSliderIndexValue = index;
    }
  }
}
