import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguagesCSVComponent } from 'src/app/helpers/languages-csv/languages-csv.component';
import { AdminService } from 'src/app/providers/admin.service';
import { StudentService } from 'src/app/providers/student.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-csv-import',
  templateUrl: './csv-import.component.html',
  styleUrls: ['./csv-import.component.scss'],
})
export class CsvImportComponent implements OnInit, OnDestroy {
  translatedText: any;
  environment = environment;
  licensesToSchool: any = [];

  currentSystemLanguage: string = 'en';
  languages: any = [];
  csvRecords: any[] = [];
  csvHeader: boolean = true;
  isCSVError: boolean = false;
  csvErrorMessage: string | null = null;
  csvFileName: string | null = null;
  csvLanguageCode: string = 'en';

  @ViewChild('fileInput', { static: false })
  fileInput: ElementRef;

  @Input('studentAmount') studentAmount: number;
  @Input('enoughLicenses') enoughLicenses: boolean;
  @Input('hasUnlimitedDiagnosticLicense') hasUnlimitedDiagnosticLicense:
    | boolean
    | false;
  @Input('hasExpiringLicenses') hasExpiringLicenses: boolean | false;
  @Input('totalCountLeftDiagnosticExp') totalCountLeftDiagnosticExp: number;
  @Input('totalCountLeftTraining') totalCountLeftTraining: number;
  @Input('totalCountLeftTrainingExp') totalCountLeftTrainingExp: number;
  @Input('totalCountLeftDiagnostic') totalCountLeftDiagnostic: number;
  @Input('totalCountPending') totalCountPending: number;
  @Input('erasedCSVRecords') erasedCSVRecords: boolean;
  @Output() csvData = new EventEmitter<any>();
  @Output() csvError = new EventEmitter<boolean>();

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private translationService: TranslationService,
    private studentService: StudentService,
    private adminService: AdminService,
    private ngxCsvParser: NgxCsvParser,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    if (this.erasedCSVRecords) {
      this.csvData.emit([]);
      this.csvRecords = [];
      this.csvFileName = null;
    }
  }

  downloadLanguageFile() {
    this.studentService
      .getLanguages()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        //console.log(res);
        this.languages = res;
        this.generateLanguageData();
      });
  }
  generateLanguageData() {
    let languageCellTitle = this.translatedText['language_list_cell_title'];
    let languageCodeTitle = this.translatedText['language_list_code_title'];

    if (!languageCellTitle) {
      languageCellTitle = 'Sprache';
    }

    if (!languageCodeTitle) {
      languageCodeTitle = 'Code';
    }

    var languages = `${languageCellTitle}; ${languageCodeTitle}\n`;
    for (const lan of this.languages) {
      const language = this.languages.filter((obj) => obj.code == lan.code);
      const enLan = this.translatedText[language[0]?.code];
      languages += `${enLan};${language[0]?.code}\n`;
    }
    const fileContent = languages;
    const fileName = 'languages.csv'; // Or 'data.txt' if you prefer .txt extension
    const blob = new Blob([fileContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    // Trigger the download by programmatically clicking the <a> element
    a.click();
    // Clean up by revoking the object URL after the download
    window.URL.revokeObjectURL(url);
  }

  downloadCSVTemplate() {
    let currentLanguage = this.currentSystemLanguage;
    if (currentLanguage === null) {
      currentLanguage = environment.language;
    }
    let link = document.createElement('a');
    if (currentLanguage.trim() === 'en') {
      link.download = 'students_template.csv';
      link.href = 'assets/file/students_template.csv';
    } else {
      link.download = 'profildaten_lernende.csv';
      link.href = 'assets/file/profildaten_lernende.csv';
    }
    link.click();
    link.remove();
  }

  onFileChanged(event) {
    this.isCSVError = false;
    const uploadData = event.target.files[0];
    if (!this.ngxCsvParser.isCSVFile(uploadData)) {
      this.isCSVError = true;

      this.csvErrorMessage =
        this.translatedText.admin_group_class_csv_import_upload_err_msg;
      return false;
    }
    // CSV Parser
    this.ngxCsvParser
      .parse(uploadData, {
        header: this.csvHeader,
        delimiter: ';',
        encoding: 'utf8',
      })
      .pipe()
      .subscribe({
        next: (result: any): void => {
          if (result && result.length > 0) {
            // if Language is English but trying to upload German file
            let firstKey = Object.keys(result[0])[0];
            if (firstKey.toLowerCase() === 'vorname') {
              this.csvLanguageCode = 'de';
            }
            if (
              this.currentSystemLanguage.trim() !== 'en' ||
              firstKey.toLowerCase() === 'vorname'
            ) {
              result = this.renameKeyInJson(result, [
                { oldKey: 'Vorname', newKey: 'Firstname' },
                { oldKey: 'Nachname', newKey: 'Lastname' },
                { oldKey: 'Muttersprache', newKey: 'Language' },
                { oldKey: 'Geburtsmonat und -jahr', newKey: 'BirthMonthYear' },
                { oldKey: 'Geschlecht', newKey: 'Gender' },
              ]);
            }
          }

          if (
            result &&
            result.length > 0 &&
            result[0]['Firstname'] !== '' &&
            result[0]['Firstname'] !== null
          ) {
            this.csvRecords = result;
            this.csvFileName = uploadData.name;
            this.adminService.updateClassFormStatus({
              form: 'studentCSVMode',
              status: 'VALID',
            });
            this.csvData.emit(this.csvRecords);
          } else {
            this.isCSVError = true;
            this.csvErrorMessage =
              this.translatedText.admin_group_class_csv_import_upload_format_err;
          }

          this.fileInput.nativeElement.value = '';
        },
        error: (error: NgxCSVParserError): void => {
          this.isCSVError = true;
          this.csvErrorMessage =
            this.translatedText.admin_group_class_csv_import_upload_format_err;
        },
      });
    this.csvError.emit(this.isCSVError);
  }

  // Irrespective of language, make it common column name from CSV
  renameKeyInJson(json, keys) {
    let dataStr = JSON.stringify(json);
    keys.forEach((e) => {
      dataStr = dataStr.replace(
        new RegExp(`"${e.oldKey}":`, 'g'),
        `"${e.newKey}":`,
      );
    });
    return JSON.parse(dataStr);
  }

  openLanguagesCSVOverlay() {
    let dialogRef = this.dialog.open(LanguagesCSVComponent, {
      width: '100%',
      autoFocus: false,
      panelClass: 'languages-csv-panel',
      disableClose: true,
      backdropClass: 'session-upload-overlay-panel_backdrop',
      data: {},
    });
    // dialogRef.disableClose = true;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {});
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
