import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';
import { environment } from 'src/environments/environment';
import { AccountDeleteComponent } from '../account-delete/account-delete.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-admin-profile-data',
  templateUrl: './admin-profile-data.component.html',
  styleUrls: ['./admin-profile-data.component.scss'],
})
export class AdminProfileDataComponent implements OnInit, OnDestroy {
  restServerUrl;
  teacherShortName: string;
  firstName: string;
  lastName: String;
  emailId: string;
  userType;
  rForm: FormGroup;
  filteredItems: Observable<any[]>;
  newSalutationItem: FormControl;
  salutation: string[] = ['Herr', 'Frau', 'Divers'];
  showAddButton: boolean = false;
  prompt = 'Drücken Sie die <enter> Taste um einen neue Anrede hinzuzufügen"';
  teacherId;
  isExpert;
  translatedText: any;

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialog: MatDialog, // TODO: private or public?
    public snackBar: MatSnackBar, // TODO: private or public?
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private teacherService: TeacherService,
    private translationService: TranslationService,
  ) {
    this.restServerUrl = environment.evolutionAPI;
  }

  ngOnInit() {
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.setTranslation(translatedText);
      });

    this.teacherId = this.authService.getTeacherId();
    this.teacherService
      .readSingleTeacher(this.teacherId)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res: any) => {
        this.rForm.get('salutation').setValue(res['salutation']);
        this.rForm.get('firstname').setValue(res['firstname']);
        this.rForm.get('lastname').setValue(res['lastname']);
        this.rForm.get('isExpert').setValue(res['isExpert']);
        this.rForm.get('otpEnabled').setValue(res['otpEnabled']);
        this.isExpert = res['isExpert'];
        this.firstName = res.firstname;
        this.lastName = res.lastname;
        this.emailId = res.email;
        this.teacherShortName = res.firstname + ' ' + res.lastname;
      });

    this.newSalutationItem = new FormControl();
    setTimeout(() => {
      this.filteredItems = this.newSalutationItem.valueChanges.pipe(
        startWith(''),
        map((item) =>
          item ? this.filterItems(item) : this.salutation?.slice(),
        ),
      );
    }, 1500);

    this.rForm = this._formBuilder.group({
      salutation: ['', Validators.required],
      firstname: ['', [Validators.required, this.noDigitsValidator()]],
      lastname: ['', [Validators.required, this.noDigitsValidator()]],
      isExpert: [false],
      otpEnabled: [false],
    });
  }

  setTranslation(translatedText: any) {
    this.translatedText = translatedText;
    this.salutation = [
      this.translatedText.salutation_male,
      this.translatedText.salutation_female,
      this.translatedText.salutation_divers,
    ];
  }

  noDigitsValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      if (value && !/^\D*$/.test(value)) {
        return { hasDigits: true };
      }
      return null;
    };
  }

  filterItems(name: string) {
    let results = this.salutation.filter(
      (item) => item.toLowerCase().indexOf(name.toLowerCase()) === 0,
    );

    this.showAddButton = results.length === 0;
    if (this.showAddButton) {
      results = [this.prompt + name + '"'];
    }

    return results;
  }

  submitForm(form: FormGroupDirective) {
    if (this.rForm.invalid) {
      return;
    }
    let formData = {
      teacherId: this.teacherId,
      salutation: this.rForm.value.salutation,
      firstname: this.rForm.value.firstname,
      lastname: this.rForm.value.lastname,
      isExpert: this.rForm.value.isExpert,
      otpEnabled: this.rForm.value.otpEnabled,
    };

    this.teacherService.updateTeacher(formData).subscribe({
      next: (res) => {
        // this.dialog.closeAll();
      },
      error: (error: any) => {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: this.translatedText?.technical_error,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  openDeleteAccountOverlay() {
    let dialogRef = this.dialog.open(AccountDeleteComponent, {
      width: '85vw',
      autoFocus: false,
      disableClose: true,
      panelClass: 'delete-overlay-panel',
      backdropClass: 'license-add-backdrop',
      data: {},
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
