<div class="teacher-list-container">
  <div class="teacher-content-container">
    <div class="admin-teacher-header">
      <div class="title_with_count">
        <h3 class="h3 h3--bold">
          {{ translatedText.admin_teacher_list_teachers }}
        </h3>
        <span class="teacher_count">{{ dataSource?.meta?.totalItems }}</span>
      </div>
      <div class="admin-teacher-header_buttons">
        <button mat-flat-button color="primary" type="button" (click)="createTeacherOverlay()">
          <mat-icon svgIcon="streamline_outlined:add-circle"></mat-icon>
          {{ translatedText.admin_teacher_list_create_teacher }}
        </button>
      </div>
    </div>
    <div class="teacher-list-filter-wrap">
      <div class="teacher-list-filter-selection"></div>
      <mat-form-field class="full-width search-teacher" floatLabel="never">
        <mat-label>{{ translatedText.admin_teacher_list_search }}</mat-label>
        <input matInput type="text" (input)="onSearchInputFilterChange($event)"
          placeholder="{{ translatedText.admin_teacher_list_search_placeholder }}" /><button mat-mini-fab
          class="not-filled">
          <mat-icon svgIcon="streamline_outlined:search-2"></mat-icon>
        </button>
      </mat-form-field>
    </div>

    <div class="table-container teacher-list">
      <table mat-table [dataSource]="dataSource?.data" matSort matSortDisableClear [matSortActive]="sortBy"
        [matSortDirection]="matSortDirection" (matSortChange)="onSortChange($event)">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ translatedText.admin_teacher_list_fullname }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="cell-container">
              <div class="teacher_with_icon teacher_with_icon--gray">
                <mat-icon class="teacher_icon" svgIcon="streamline_filled:round-user"></mat-icon>
                <p class="paragraph paragraph--s">
                  {{ element?.name }}
                  <span *ngIf="element?._user.role == 'school_admin'" class="admin-chip">ADMIN</span>
                </p>
              </div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>
            {{ translatedText.admin_teacher_list_email }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="cell-container"
              [matTooltip]="shortenEmail(element?._user.email) === element?._user.email ? '' : element?._user.email"
              matTooltipPosition="below">
              {{ shortenEmail(element?._user.email) }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="group">
          <th mat-header-cell *matHeaderCellDef>
            {{ translatedText.admin_teacher_list_group }}
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.invitation_status !== 'pending' && element.invitation_status !== 'declined'">
              <div class="table_group cell-container">
                <span *ngFor="let group of element?._group" class="class_chip teacher_dashboard">
                  <mat-icon svgIcon="streamline_filled:class-hat-small"></mat-icon>
                  {{ group?.name }} - {{ group?.school_year | slice : 2 }}
                </span>
              </div>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ translatedText.admin_teacher_list_status }}
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="cell-container">
              <ng-container *ngIf="element.invitation_status !== 'pending' && element.invitation_status !== 'declined'">
                <ng-container *ngIf="element.status == 'active'">
                  <mat-icon matTooltip="{{ translatedText.admin_teacher_list_active }}" color="primary"
                    svgIcon="streamline_outlined:license_available" class="icon-40"></mat-icon>
                </ng-container>
                <ng-container *ngIf="element.status == 'inactive'">
                  <mat-icon matTooltip="{{ translatedText.admin_teacher_list_inactive }}" color="warn"
                    svgIcon="streamline_outlined:cancel_circle" class="icon-40"></mat-icon>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="element.invitation_status == 'pending'">
                <mat-icon color="warn" svgIcon="streamline_outlined:hourglassicon" class="icon-40"></mat-icon>
              </ng-container>

            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="buttons">
          <th mat-header-cell *matHeaderCellDef class="hidden_nested_header">
            <!-- {{ translatedText.admin_teacher_list_action }} -->
          </th>
          <td mat-cell *matCellDef="let element">
            <div
              *ngIf="(userType == 'teacher' && element?._user._id === loggedInAccountId) || userType == 'school_admin'"
              class="cell-container">
              <button mat-flat-button color="unset" class="more_action desktop-visible"
                [matMenuTriggerFor]="moreTeacherMenu" [ngClass]="{ action_select: openedMenu === element._id }"
                mat-flat-button type="button" (click)="clickedOptions(element._id)">
                <mat-icon svgIcon="streamline_filled:down_arrow"></mat-icon>
                {{ translatedText.admin_teacher_list_options }}
              </button>
              <button mat-flat-button color="unset" class="tablet-visible" [matMenuTriggerFor]="moreTeacherMenu"
                [ngClass]="{ action_select: openedMenu === element._id }" mat-flat-button type="button"
                (click)="clickedOptions(element._id)">
                <mat-icon svgIcon="streamline_filled:more-vert"></mat-icon>
              </button>
              <mat-menu #moreTeacherMenu="matMenu" (closed)="closeMenu()">
                <!-- <button
                    mat-menu-item
                    aria-label="view-detail"
                    (click)="impersonateUser(element?._user?._id)"
                    *ngIf="userType == 'admin'"
                  >
                    <mat-icon svgIcon="streamline_outlined:verified-user"></mat-icon>
                    <span>{{ translatedText.admin_teacher_list_act_as_teacher }}</span>
                  </button> -->
                <ng-container
                  *ngIf="element.invitation_status !== 'pending' && element.invitation_status !== 'declined'">
                  <button *ngIf="userType == 'school_admin' || userType == element?._user.role" mat-menu-item
                    aria-label="edit details"
                    (click)="editTeacher(element._id, element?._user.email, element?.user_userType)">
                    <mat-icon svgIcon="streamline_outlined:edit-pencil"></mat-icon>
                    {{ translatedText.admin_teacher_list_edit_teacher_info }}
                  </button>

                  <!-- remove admin rights -->
                  <button *ngIf="
                    userType == 'school_admin' &&
                    loggedInAccountId !== element?._user._id &&
                    element?._user.role == 'school_admin'
                  " mat-menu-item (click)="updateAdminRights('demote', element)" aria-label="remove admin rights">
                    <mat-icon *ngIf="element?._user.role == 'school_admin'" svgIcon="streamline_outlined:remove-admin">
                    </mat-icon>
                    {{ translatedText.admin_edit_revoke_admin_rights }}
                  </button>

                  <!-- add admin rights -->
                  <button *ngIf="element?._user.role !== 'school_admin' && userType == 'school_admin'" mat-menu-item
                    aria-label="add admin rights" (click)="updateAdminRights('promote', element)">
                    <mat-icon svgIcon="streamline_outlined:add-admin"></mat-icon>
                    {{ translatedText.admin_edit_add_admin_rights }}
                  </button>

                  <!-- set active/inactive -->
                  <button *ngIf="loggedInAccountId !== element?._user._id && userType == 'school_admin'" mat-menu-item
                    aria-label="view-detail" (click)="updateTeacherStatus(element)">
                    <mat-icon svgIcon="streamline_outlined:pause-window"></mat-icon>
                    <span>{{
                    element?.status == 'active'
                      ? translatedText.admin_teacher_list_set_inactive
                      : translatedText.admin_teacher_list_set_active
                  }}</span>
                  </button>

                  <!-- delete teacher -->
                  <button *ngIf="loggedInAccountId !== element?._user._id && userType == 'school_admin'"
                    class="trash-menu-item" mat-menu-item aria-label="view-detail"
                    (click)="openTeacherDeleteDialog(element)">
                    <!-- *ngIf="
                      element?._user.role != 'school_admin' &&
                      element?._tasks.length == 0 &&
                      element?._group.length == 0
                    " -->
                    <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                    <span>{{ translatedText.admin_teacher_list_delete }}</span>
                  </button>
                </ng-container>
                <ng-container *ngIf="element.invitation_status == 'pending' || element.invitation_status == 'declined'">
                  <button *ngIf="element.invitation_status == 'declined'" mat-menu-item aria-label="add admin rights"
                    (click)="resendInvitationOverlay(element)">
                    <mat-icon svgIcon="streamline_outlined:send-mail"></mat-icon>
                    {{ translatedText.invitation_resend }}
                  </button>
                  <button mat-menu-item aria-label="add admin rights" (click)="deleteInvitationOverlay(element)">
                    <mat-icon svgIcon="streamline_outlined:trash"></mat-icon>
                    {{ translatedText.admin_teacher_list_delete }}
                  </button>
                </ng-container>
              </mat-menu>
            </div>
          </td>
        </ng-container>

        <tr mat-footer-row *matFooterRowDef="['noRecord']" [hidden]="dataSource?.data.length > 0"></tr>
        <ng-container matColumnDef="noRecord">
          <mat-footer-cell *matFooterCellDef colspan="2">
            <span>
              {{ translatedText.admin_teacher_list_no_results }}
            </span>
          </mat-footer-cell>
        </ng-container>
      </table>
    </div>
    <mat-paginator [hidePageSize]="true" [length]="dataSource?.meta.totalItems" [pageSize]="
        dataSource?.meta.totalItems > dataSource?.meta.itemsPerPage
          ? dataSource?.meta.itemsPerPage
          : dataSource?.meta.totalItems
      " [pageSizeOptions]="[10, 25, 100]" (page)="pageEvent = $event; onPaginateChange($event)" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>
