import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { TeacherService } from 'src/app/providers/teacher.service';
import { TranslationService } from 'src/app/providers/translation.service';

import {
  ReactiveFormsModule,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { AdminService, TeacherData } from 'src/app/providers/admin.service';
import { ErrorSnackbarComponent } from 'src/app/helpers/snackbar/error-snackbar/error-snackbar.component';
import { SuccessSnackbarComponent } from 'src/app/helpers/snackbar/success-snackbar/success-snackbar.component';
import { TaskService } from 'src/app/providers/task.service';
import { AuthService } from 'src/app/auth/auth.service';
import { CdkStepper } from '@angular/cdk/stepper';

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-teacher-delete',
  templateUrl: './teacher-delete.component.html',
  styleUrls: ['./teacher-delete.component.scss'],
})
export class TeacherDeleteComponent implements OnInit, OnDestroy {
  rForm: FormGroup;
  translatedText: any;
  isLoading: boolean = false;
  isClosedDialog: boolean = false;
  selectedIndex: number = 0;
  @ViewChild('stepper') stepper: CdkStepper;

  teacherId;
  teacherName;
  teacherElement;
  replaceTeacher: boolean = false;
  teachers;
  selectedTeacher;
  teachersLoaded: boolean = false;
  dataSource: TeacherData = null;

  groupId;
  groupName;
  selectedText;

  userType;
  transferTasks: boolean = false;

  //admin service
  page = 1;
  limit = 1000;
  sortBy = 'lastname';
  sortDirection = 1;
  matSortDirection = 'asc';
  filterValues = {
    search: '',
    groups: [],
    status: [],
    role: [],
  };

  private _unsubscribeAll: Subject<boolean> = new Subject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) @Optional() public injectedData: DialogData,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private adminService: AdminService,
    private authService: AuthService,
    private teacherService: TeacherService,
    private taskService: TaskService,
    private translationService: TranslationService,
  ) {
    if (injectedData) {
      if (typeof injectedData['teacher'] !== 'undefined') {
        this.teacherElement = this.injectedData['teacher'];
      }
      if (typeof injectedData['teacherId'] !== 'undefined') {
        this.teacherId = this.injectedData['teacherId'];
      }
      if (typeof injectedData['teacherName'] !== 'undefined') {
        this.teacherName = this.injectedData['teacherName'];
      }
    }
  }

  ngOnInit() {
    this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
      });
    this.userType = this.authService.getType();
    //console.log(this.userType);
    this.rForm = this._formBuilder.group({
      teacher: ['', Validators.required],
    });
    this.getTeachers();
  }

  //API call on adminService to retrieve requested teacher
  getTeachers() {
    this.adminService
      .getTeacherList(
        this.page,
        this.limit,
        this.sortBy,
        this.sortDirection,
        this.filterValues,
      )
      .subscribe({
        next: (teacherData: TeacherData) => {
          teacherData.data.forEach((item) => {
            // combine "firstname" & "lastname" to "name"-Attribut
            item['name'] = `${item['firstname']} ${item['lastname']}`;
          });
          this.teachers = teacherData.data.filter(
            (teacher) => teacher._id != this.teacherElement._id,
          );
          this.teachersLoaded = true;
        },
        error: () => (this.teachersLoaded = true),
        complete: () => (this.teachersLoaded = true),
      });
  }

  checkForTaskTransfer() {
    if (this.teacherElement._tasks.length == 0) {
      this.deleteTeacher();
    } else {
      this.selectedIndex = 1;
    }
  }

  deleteTeacher() {
    this.teacherService.deleteTeacher(this.teacherElement._id).subscribe({
      next: (res) => {
        this.selectedIndex = this.selectedIndex + 1;
        this.isLoading = false;
        this.snackBar.openFromComponent(SuccessSnackbarComponent, {
          panelClass: 'snack-success',
          data: this.translatedText?.teacher_delete_success,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
      error: (error) => {
        let message = this.translatedText?.technical_error;
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          panelClass: 'snack-error',
          data: message,
          duration: 3000,
          horizontalPosition: 'left',
        });
      },
    });
  }

  submitForm() {
    if (this.rForm.invalid) {
      console.log('Form invalid');
      return;
    }
    this.isLoading = true;
    const taker_teacher_id = this.rForm.value.teacher;

    if (this.transferTasks) {
      this.taskService
        .transferTasksToTeacher(
          this.teacherElement._id,
          taker_teacher_id,
          this.groupId,
        )
        .subscribe({
          next: () => {
            this.deleteTeacher();
          },
          error: (error) => {
            this.isLoading = false;
            console.error(error);
          },
        });
    } else {
      this.deleteTeacher();
    }
  }

  assignTeacher() {
    this.replaceTeacher = true;
    this.transferTasks = true;
    const taker_teacher_id = this.rForm.value.teacher;
    this.teacherName = this.injectedData['teacherName'];
    this.teachers.forEach((teacher) => {
      if (teacher._id === taker_teacher_id) {
        this.selectedTeacher = teacher.firstname + ' ' + teacher.lastname;
      }
    });
    // trigger submit
    this.submitForm();
  }

  closeOverlay(event: boolean): void {
    this.dialog.closeAll();
    this.isClosedDialog = event;
  }

  getActiveSelection(selection) {
    this.selectedIndex = selection.selectedIndex;
  }

  notAssignTeacher() {
    this.replaceTeacher = false;
    this.transferTasks = false;
    this.dialog.closeAll();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
  }
}
