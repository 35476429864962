import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/providers/translation.service';
import { CapacitorService } from 'src/app/providers/capacitor.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export type UpgradeDialogData = {
  upgradeModalText?:
    | 'maxGroupsReached'
    | 'maxStudentsReached'
    | 'maxTeachersReached'
    | 'task'
    | 'readingMinutes';
};

@Component({
  selector: 'app-schools-only',
  templateUrl: './schools-only.component.html',
  styleUrls: ['./schools-only.component.scss'],
})
export class SchoolsOnlyDialogComponent implements OnInit, OnDestroy {
  environment = environment;
  translatedText: any;
  schoolsOnlyInfoText;

  private _unsubscribeAll = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<SchoolsOnlyDialogComponent>,
    private capacitorService: CapacitorService,
    private sanitizer: DomSanitizer,
    private translationService: TranslationService,
    @Inject(MAT_DIALOG_DATA) public data: UpgradeDialogData,
  ) {}

  ngOnInit(): void {
    // this.translatedText = this.route.snapshot.firstChild.data.translation;
    this.translationService
      .getTranslation()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((translatedText: any[]) => {
        this.translatedText = translatedText;
        // laletu fanily
        this.schoolsOnlyInfoText = this.sanitizer.bypassSecurityTrustHtml(
          this.translatedText.t('schools_only_info_text', {
            laletufamilylink: `<a href="https://www.laletu.de/family" class="link" target="_blank">${this.translatedText.schools_only_info_family}</a>`,
          }),
        );
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  openUrl(url: string) {
    this.capacitorService.openUrl(url);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
