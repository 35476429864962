export const environment = {
  production: true,
  ssl: true,
  appVersion: require('../../package.json').version,
  hotfixVersion: require('../../package.json').hotfix,
  isApp: false,
  evolutionAPI: 'https://evolution-api.scw-dev.digi-sapiens.net',
  CLOUDAPI_SERVER: 'https://api-readalizer.scw-dev.digi-sapiens.net',
  BOOKREADERAPI_SERVER:
    'https://ebcont-reader-backend.scw-dev.digi-sapiens.net',
  externalAssets: 'https://cdn-internal-assets.scw-dev.digi-sapiens.net',
  userbackActive: true,
  userbackAccessToken: 'P-0KT5dRFCMKXCAB3GAzb5K4G2j',
  sentryDSN:
    'https://e6d1e772814f4bb689e1c2281eaa0b10@o1330715.ingest.sentry.io/4505511324155904',
  sentryEnvironment: 'dev',
  gaTrackId: 'G-4VV6WX7MVZ',
  clientId: 'dev.laletu.app',
  language: 'de_de',
  readalizer_customer: '054e5982-ae41-45b4-a029-f1dcead3429b',
  whitelabel: require('./whitelabel/default.js'),
  liveUpdateChannel: 'Development',
  maintenanceUrl:
    'https://updates.digi-sapiens.de/evolution/dev.laletu.app/maintenance.json',
  iosAppUrl: 'https://apps.apple.com/app/testflight/id6739540318',
  androidAppUrl: 'market://details?id=dev.laletu.app',
};
