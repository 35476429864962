<div class="csv-languages-container">
  <button mat-mini-fab type="button" class="close-button close-button--right" [mat-dialog-close]="'close'" matSuffix>
    <mat-icon svgIcon="streamline_outlined:close"></mat-icon>
  </button>
  <h2 class="h2 h2--bold">{{ translatedText.csv_languages_overlay_title }}</h2>
  <app-loading-spinner class="loading-spinner" *ngIf="isLoading"></app-loading-spinner>
  <div *ngIf="!isLoading" class="csv-languages-wrap">
    <p class="language-item" *ngFor="let language of languages">
      {{ translatedText[language.code] }}
    </p>
  </div>
</div>
